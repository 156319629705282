import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { Header } from '../common/Header/Header'
import BtnAdd from '../common/BarMenu/btnAdd';
import initData from '../Home/initData';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ModalStatusPayment from './ModalStatusPayment';
import BarMenu from '../common/BarMenu/BarMenu';
import { EvertechFetchAPI } from '../../api/Evertech';
import { generateRandomString } from '../../utils/RandomString';
import { PreOrderFetchAPI } from '../../api/PaymentPost/PreOrder';
import { OrderNumberFetchAPI } from '../../api/PaymentPost/OrderNumber';
import { TablesFetchAPI } from '../../api/Tables';
import { MachinesFetchAPI } from '../../api/Machines';
import { PaymentMethodFetchAPI } from '../../api/PaymentPost/PaymentMethod';
import Socket from '../../utils/Socket.config';
import ModalNfc from './ModalNfc';
import Modal from 'react-bootstrap/Modal';
import LoginUser from '../Profile/LoginUser';
import Toolbar from '../../utils/ToolBarVisible';
//CSS
import './cart.css';
import Payments from './Payments';

export const ShoppingCart = () => {
   const navigate = useNavigate();
   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [handlerEvent, setHandlerEvent] = useState(0);
   const [modalShow, setModalShow] = React.useState(false);
   const [shoppingcart, setShopingcart] = useState([]);
   const [shoppingCartCombos, setShoppingCartCombos] = useState([])
   const [payments, setPayments] = useState([]);
   const [selectedPayment, setSelectedPayment] = useState({})
   const [showModalConfirmation, setShowModalConfirmation] = useState(false)
   const [dataDicountApply, setdataDiscountApply] = useState([])
   const [showModalStatusPayment, setShowModalStatusPayment] = useState(false)
   const [modalUser, setModalUser] = React.useState(false);
   const [client_name, setClient_name] = useState("Cliene anonimo")
   const [id_Client, setId_Client] = useState(0)
   const [showModalPlaceTopay, setShowModalPlaceTopay] = useState(false)
   const [enabled, setEnabled] = useState(true)


   //Totals states 
   const [invoiceTip, setInvoiceTip] = useState(0)
   const [totaltopay, settotaltopay] = useState(0)
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0);
   const [taxesArrayAll, setTaxesArrayAll] = useState([]);
   let table = localStorage.getItem('table');
   const [tableInfo, setTableInfo] = useState(() => {
      if (table !== null && table !== undefined && table !== '') {

         return (JSON.parse(table))
      } else {
         return { table_code: "", name: "", id: "" }
      }
   });

   if (table !== null && table !== undefined && table !== '') {
      table = JSON.parse(table);

   }

   //states for the register 
   const [registerTurnOrder, setRegisterTurnOrder] = useState(0);
   const [yappy_transaction_id, setYappy_transaction_id] = useState("");
   const [dataPreOrder, setDataPreOrder] = useState({ pre_Order: false, _id: "" });
   const [datatransaction_code, setDataTransaction_code] = useState("");
   const [order_id, setOrder_id] = useState("");
   const [order_code, setOrderCode] = useState("");
   const [orderNumber, setOrderNumber] = useState("0")
   const [orderNumberNow, setOrderNumberNow] = useState("0")
   const [lastOrder_id, setLastOrder_id] = useState("");
   const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago..." })
   const [statePayment, setStatePayment] = useState(false);
   const [newTab, setNewTab] = useState(null);
   const [urlPlaceToPay, setUrlPlaceToPay] = useState("");


   let machine = "";
   let business = "";
   let branchoffice = "";

   if (localStorage.getItem("x-access-machine-token") != "" && localStorage.getItem("x-access-machine-token") != undefined && localStorage.getItem("branchoffice") != "" && localStorage.getItem("branchoffice") != undefined && localStorage.getItem("machine") != "" && localStorage.getItem("machine") != undefined) {
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      machine = JSON.parse(localStorage.getItem('machine'));
      business = JSON.parse(localStorage.getItem("business"));
   } else {
      navigate(`/branch/${business_code}`)
   }

   //=====================CLEAR SHOPPINGCART=============================
   const clearShoppingcart = () => {
      clearShoppingCartlocalStorage();
      setShoppingCartCombos([])
      setShopingcart([])
      calculateInvoiceTotals([], [])
      navigate(`/home/${business_code}/${branchoffice_code}/${machine_code_table}`);
   }

   //clear SHOPPINGCART
   const clearShoppingCartlocalStorage = () => {
      localStorage.setItem('shoppingCart', '[]')
      localStorage.setItem('shoppingCartCombos', '[]')
   }
   //==========================CALCULATE===========================

   //decimal reduction
   const decimalReduction = (value) => {

      let string = value.toString()
      let arrayString = string.split(".");
      let newDecimalString = []
      let sample = ''
      if (arrayString[1] != undefined) {
         newDecimalString = arrayString[1].slice(0, 2);
         sample = arrayString[0] + "." + newDecimalString
         console.log("Hice la reduccion");
      } else {
         console.log("Array: " + arrayString);
         console.log("Decimal: " + newDecimalString);
         console.log("Nuevo valor: " + sample);
         sample = string;
      }

      let endValue = parseFloat(sample)
      return endValue
   }

   // Función para redondear hacia arriba si el tercer decimal es mayor a 0
   function roundUpToTwoDecimals(value) {
      console.log("Nuevo redondeo de tax");
      console.log(value);
      // Verificamos si hay más de dos decimales
      const decimals = value.toString().split('.')[1];

      if (decimals && decimals.length > 2) {
         // Si el tercer decimal es mayor que 0, redondeamos hacia arriba
         if (parseInt(decimals[2]) > 0) {
            return Math.ceil(value * 100) / 100;
         }
      }

      console.log(parseFloat(value.toFixed(2)));

      // Si ya tiene dos decimales o menos, devolvemos el valor tal cual
      return parseFloat(value.toFixed(2));
   }

   //Function to calculate the invoice totals
   const calculateInvoiceTotals = (products, combos) => {

      //For every new calculate we set the totals
      let taxesArray = [];
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //if we dont we products we put the totals in 0 for default
      if ((products.length + combos.length) === 0) {
         //We calculate the totals of the invoice
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setTaxesArrayAll(taxesArray)
      } else {

         //For every combos we calculate
         combos.forEach((combo) => {

            byProductandCombo(combo)
         })

         products.forEach((group) => {
            byProductandCombo(group)
         })

         //Esta funcion calcula los valores por producto o combo por posicion
         function byProductandCombo(item) {

            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product
            product_total_discount = (item.unit_discount * item.amount);
            //We set the total of the product
            total_product_price = (item.amount * (item.price - item.unit_discount));

            //Calculate tax by group--------------------------------
            let taxesGroup = item.taxes;
            taxesGroup.forEach(tax => {
               //calculate tax  value and add it to array

               let valueTax = roundUpToTwoDecimals((item.amount * ((item.price - item.unit_discount) * tax.taxtValue)));
               console.log(valueTax);
               tax.total = valueTax;
               //Identifier tax of the group position
               var index = taxesArray.findIndex(function (info, i) {
                  return info.tax_id === tax.tax_id
               });

               if (index < 0) {
                  let taxInfoNow = {
                     "dTasaITBMSValue": tax.dTasaITBMSValue,
                     "percentage": tax.percentage,
                     "tax": tax.tax,
                     "tax_id": tax.tax_id,
                     "taxtValue": tax.taxtValue,
                     "total": valueTax
                  }
                  taxesArray.push(taxInfoNow);
               } else {
                  taxesArray[index].total = (taxesArray[index].total + valueTax)
               }
               total_product_itbms = (total_product_itbms + valueTax)

            });
            //-------------------------------------------------------
            //We set the itbms of the product
            //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

            //We calculate the totals of the invoice
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = decimalReduction(invoiceSubtotal + total_product_price);
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms

            console.log("invoiceTotalDiscount:" + invoiceTotalDiscount);
            console.log("invoiceSubtotal:" + invoiceSubtotal);
            console.log("invoiceTotalItbms:" + invoiceTotalItbms);
            console.log("invoiceTotal:" + invoiceTotal);
         }


         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);
         setTaxesArrayAll(taxesArray);
         settotaltopay((invoiceTotal + invoiceTip))

         let newArray = []
         //valid discount report
         dataDicountApply.forEach((discount, index) => {
            let discountvalid = 0
            discount.groups.forEach((group) => {
               let productfind = products.find(({ group_id }) => group_id === group.group_id)
               if (productfind) {
                  discountvalid = discountvalid + productfind.discount
               }
            })

            discount.combos.forEach((combo) => {
               let combofind = combos.find(({ combo_id }) => combo_id === combo.combo_id)
               if (combofind) {
                  discountvalid = discountvalid + combofind.discount
               }
            })


            discount.index = index
            if (discountvalid == 0) {
               let indexRemove = index
               dataDicountApply.forEach((item, index) => {
                  if (item.index != indexRemove) {
                     newArray.push(item)
                  }
               })

            } else {
               if (discountvalid != discount.total) {
                  discount.total = discountvalid
               }
            }
         })


         if (newArray.length > 0) {
            setdataDiscountApply(newArray)
         } else {
            setdataDiscountApply(dataDicountApply)
         }
      }

      setHandlerEvent(1)
   }

   //==========================EDIT AMOUNTS PRODUCTS ============================

   //Function to incremet the product amount on the shopping cart
   const decrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingcart[product_index];
      //We affect the product data
      product.amount = product.amount - 1;
      product.cantidadItem = product.amount
      product.descuento = product.descuento - product.unit_discount;
      product.discount = product.discount - product.unit_discount;

      //We check if the product amount is lower than 0
      if (product.amount <= 0) {
         //if is lower or equal to 0 we delete the product of the shopping cart list
         shoppingcart.splice(product_index, 1);
         setShopingcart([...shoppingcart])
      }

      localStorage.setItem('shoppingCart', JSON.stringify(shoppingcart))
      //recharge shoppingcart
      calculateInvoiceTotals(shoppingcart, shoppingCartCombos)
   };

   //Function to incremet the product amount on the shopping cart
   const incrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingcart[product_index];
      //We affect the product data
      product.amount = product.amount + 1;
      product.cantidadItem = product.amount;
      product.descuento = product.descuento + product.unit_discount;
      product.discount = product.discount + product.unit_discount;

      localStorage.setItem('shoppingCart', JSON.stringify(shoppingcart))
      //recharge shoppingcart
      calculateInvoiceTotals(shoppingcart, shoppingCartCombos)
   };


   //----------------------Data general-----------------------\

   //consolidates data into corresponding statements
   const dataconsolidation = (generalData) => {
      if (generalData == 'error') { navigate(`/branch/${business_code}`) }
      let data = generalData
      let dataCombos = []
      let dataGroups = []
      if (localStorage.getItem('shoppingCart') == null || localStorage.getItem('shoppingCart') == undefined || localStorage.getItem('shoppingCart') == "") {
         setShopingcart([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCart'))
         dataGroups = datashopingcart
         setShopingcart(datashopingcart)
      }

      if (localStorage.getItem('shoppingCartCombos') == null || localStorage.getItem('shoppingCartCombos') == undefined || localStorage.getItem('shoppingCartCombos') == "") {
         setShoppingCartCombos([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCartCombos'))
         dataCombos = datashopingcart
         setShoppingCartCombos(datashopingcart)
      }


      if (dataGroups.length > 0 || dataCombos.length > 0) {
         calculateInvoiceTotals(dataGroups, dataCombos)
      }
      //==========Payments==============
      //setPayments(data.paymentMethods);

      console.log(data.paymentMethods);
   }

   const handlerInit = async () => {
      try {
         let response = await initData()
         dataconsolidation(response)
      } catch (err) {
         navigate(`/branch/${business_code}`)
         console.log(err);
      }
   }

   const getTableById = async (type, payment) => {
      let token = localStorage.getItem('x-access-machine-token');
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {
         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table
         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            } else {
               if (type != 'init') { createPreOrder() }
            }
         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }

   }

   //create preOrder
   const createPreOrder = async (payment) => {

      if ((shoppingcart.length + shoppingCartCombos.length) > 0) {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'))
         let json = {
            "client_name": table.client_name,
            "localizador": "00",
            "client_phone": "0",
            "ruc": "0000",
            "dv": "00",
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": shoppingcart,
            "combos": shoppingCartCombos,
            "Descuento": invoiceTotalDiscount,
            "Total": invoiceTotal,
            "itbms": invoiceTotalItbms,
            "taxes": taxesArrayAll,
            "subtotal": invoiceSubtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": "para llevar",
            "paymethod": 0,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "is_pre_order": true, "user": {
               id: 0,
               name: "",
               code: 0
            },
            "table_name": tableInfo.name,
            "table_code": tableInfo.table_code,
            "table_id": tableInfo.id,
            "table_password": tableInfo.password
         }


         if (payment == undefined) {
            if (table.open_order == 1) {
               json.is_authorized = true
            } else {
               json.is_authorized = false
            }
         }

         try {
            const response = await PreOrderFetchAPI.createPreOrder(json, token)
            let message = "Precuenta creada con exito."
            let title = "Operacion exitosa."
            let icon = "success"
            ErrorAlert(message, title, icon)
            setShowModalConfirmation(false)
            setShopingcart([])
            localStorage.setItem('shoppingCart', '[]')
            navigate(`/orders/${business_code}/${branchoffice_code}/${machine_code_table}`)

         } catch (err) {
            console.log(err);
         }
      } else {
         ErrorAlert("Debes agregar  algo al carrito", "No hay productos en el carrito", "info")
      }
   }

   //===========================REGISTER ORDER===========================
   // get order number in process
   const getOrderNumber = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice.id, token)
         setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
         setOrderNumberNow((parseInt(response.data.last_transaction_code) + 1));
      } catch (err) {
         console.log(err);
      }
   }

   //get last order in this machine
   const lastOrderMachineRequest = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let machine = JSON.parse(localStorage.getItem('machine'));
      try {
         const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);
         setLastOrder_id(response.data.last_order._id)
      } catch (err) {
         console.log(err)
      }
   }

   //function register order
   const registernewOrder = async (detailEMVStreamResponse, paymentInfo) => {
      let token = localStorage.getItem('x-access-machine-token')
      LoadingAlert("Registrando orden", "Espere un momento por favor...")
      console.log(paymentInfo);

      if (registerTurnOrder == 0) {

         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'));
         let json = {
            "user_id": 0,
            "EMVStreamResponse": detailEMVStreamResponse,
            "transaction_code": orderNumber,
            "client_name": client_name,
            "id_Client": id_Client,
            "ruc": 0,
            "dv": 0,
            "localizador": "00",
            "client_phone": "",
            "dgi_qr": "0",
            "Orden": shoppingcart,
            "combos": shoppingCartCombos,
            "Descuento": invoiceTotalDiscount,
            "Total": invoiceTotal,
            "itbms": invoiceTotalItbms,
            "tips": invoiceTip,
            "taxes": taxesArrayAll,
            "subtotal": invoiceSubtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": "para llevar",
            "paymethod": paymentInfo.payment_id,
            "paymethods": [],
            "discounts": dataDicountApply,
            "paymenthod_integration_id": paymentInfo.integration.id,
            "table_name": tableInfo.name,
            "table_id": tableInfo.id,
            "table_code": tableInfo.table_code,
            "discounts": dataDicountApply,
            "parthner_order": false
         }

         //We check if the payment integration is evertech 
         if (paymentInfo.integration.id === 6) {
            if (detailEMVStreamResponse.amounts.tip !== undefined) {
               //IF the payment integration is evertech we search the tip info and add it to the order
               json.tips = detailEMVStreamResponse.amounts.tip.split("$")[1];
            }
         }

         if (yappy_transaction_id != "") {
            json.yappy_transaction_id = yappy_transaction_id
         }

         if (dataPreOrder.pre_Order == true) {
            json.pre_order = true
            json.pre_order_code = dataPreOrder.code
         }
         try {

            const response = await OrderNumberFetchAPI.registerOrder(json, token)
            setDataTransaction_code(response.data.data.transaction_code)
            setOrder_id(response.data.data.order_id)
            setOrderCode(response.data.data.order_code)
            setRegisterTurnOrder(1);
            SuccessAlert("Orden registrada con exito", "Orden generada", "success")

            navigate(`/order/${response.data.data.order_code}/branchoffice/${branchoffice.id}/${business_code}/${branchoffice_code}/${machine_code_table}`);
            clearShoppingCartlocalStorage();
         } catch (err) {
            //valid to id of transaction 
            try {
               let token = localStorage.getItem('x-access-machine-token');
               const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);

               if (response.data.last_order._id == lastOrder_id) {
                  registerTurnOrder = 0;
                  setRegisterTurnOrder(registerTurnOrder)
                  ErrorAlert("", "Ocurrio un error", "error")
               } else {
                  console.log("La transaccion ya habia sido regitrada")
                  setDataTransaction_code(response.data.last_order.transaction_code);
                  setOrder_id(response.data.last_order.order_id)
                  setOrderCode(response.data.last_order.order_code)
                  setRegisterTurnOrder(1);
                  SuccessAlert("Orden registrada con exito", "Orden generada", "success")
                  navigate(`/order/${response.data.data.order_code}/branchoffice/${branchoffice.id}/${business_code}/${branchoffice_code}/${machine_code_table}`);
                  clearShoppingCartlocalStorage();

               }
            } catch (err) {
               console.log(err)
            }
         }
      } else {
         console.log("Hay una orden en proceso");
      }
   }


   //===========================CARD SEGMENTS AND PRODUCT===========================

   //layout card segments
   const cardSegments = (segment) => {
      return (<><div class="list-group w-100">
         <a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-3  border-0" aria-current="true">
            <div class="d-flex gap-2 w-100 justify-content-between">
               <div>
                  <h6 class=" opacity-50 mb-0">{segment.segment_name}</h6>
                  <h6 class="mb-0">{segment.item.name}</h6>
                  {/* <p class="mb-0 opacity-75">Some placeholder content in a paragraph.</p> */}
               </div>
               <small class="opacity-75 text-nowrap fw-bold">+ ${segment.price}</small>
            </div>
         </a>

      </div></>)
   }

   //layout card product
   const cardProduct = (product, index, setGroupCombo) => {
      return (<>
         <div class="ecommerce-menu-card d-flex align-items-center bg-white  border  border-1  mt-1 mx-1 p-1 rounded-4">
            <div class="flex-shrink-0">
               {setGroupCombo == false ? <><img src={product.img}
                  className='img-ecommerce-menu-card rounded-3 border' alt='...' /></> : <></>}

            </div>
            <div class="flex-grow-1 ms-3">
               <div className='mt-2 me-2'>
                  <span className='mt-1 title-ecommerce-menu-card '> {product.name} </span>
                  <div className='d-block'>
                     <details>
                        <summary>
                           Detalles
                        </summary>
                        <div className='mt-1'>

                           <div class="d-flex flex-column gap-1 align-items-center justify-content-center">
                              {/* Item 1 */}
                              {product.segments ? <>
                                 {product.segments.map((segment) => (
                                    cardSegments(segment)))}</> : <>

                                 {product.setGroups.map((setGroup) => (<>
                                    <div class="list-group w-100">{setGroup.name}</div>
                                    {setGroup.groups.map((group, index) => (
                                       cardProduct(group, index, true)
                                    ))}

                                 </>))}
                              </>}
                           </div>
                        </div>
                     </details>
                  </div>

                  <div className='d-flex justify-content-between align-items-center'>
                     <span className='mt-1 d-block value-ecommerce-menu-card '> $ {product.price.toFixed()} </span>

                     {setGroupCombo == false ? <><div className='d-flex justify-content-around gap-1 align-items-center'>
                        <div onClick={() => decrementProductAmount(index)}><span><i class="uil uil-minus-circle item-count-product"></i></span></div>
                        <span className='value-ecommerce-menu-card text-dark'>{product.amount}</span>
                        <div onClick={() => incrementProductAmount(index)}><span><i class="uil uil-plus-circle item-count-product"></i></span></div>
                     </div></> : <></>}

                  </div>

               </div>
            </div>
         </div>
      </>)
   }

   //===========================Payment===========================
   //function get payment method by machine
   const getPaymentMethod = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let machine = JSON.parse(localStorage.getItem('machine'));
      try {
         const response = await PaymentMethodFetchAPI.getPaymentMethod(machine.id, token);
         setPayments(response.data.data);
      } catch (error) {
         console.log(error);
      }
   }

   //Function to open a new tab
   const openTab = (url) => {
      console.log("URL EVERTEC", url);
      const tab = window.open(url, '_blank');
      setNewTab(tab);
   };


   //function process payment of the order
   const processPayment = async (pays) => {
      console.log(pays);
      setShowModalStatusPayment(true)
      let title = 'Cargando....'
      let message = 'Cargando información....<br/> <br/>'
      let namePayment = pays.payment

      if ((shoppingcart.length + shoppingCartCombos.length) > 0) {
         if (namePayment == 'Credito') {
            //temporalmente comenta esta parate es funcional he indispensable 
            setPaymentApproval({ status: 1, description: "Registrando ..." });
            setTimeout(() => {
               registernewOrder(0, pays)
            }, 3000);

         } else if (namePayment == 'Yappy') {

         } else if (namePayment == 'Yappy QR') {

         } else if (namePayment == "Pluxee QR") {

         } else if (namePayment == "Pluxee papel") {

         } else {
            console.log("paymentinfo es", pays)
            //We check the pay integration of the paymentmethod selected
            if (pays.integration.name === "Bac") {

            } else if (pays.integration.name === "Atm") {

            } else if (pays.integration.name === "Evertech") {

            } else if (pays.integration.name === "Pasarela de pago" && namePayment === "Evertech") {
               console.log("Evertech tipo de integracion papu placetopay");
               //evertechPaymentPlaceToPay(pays);
               openTab(urlPlaceToPay)
            } else if (pays.integration.name === "Pasarela de pago" && namePayment === "PagueloFacil") {

            } else if (pays.integration.name === "Banesco") {

            }
         }
      } else {
         //ErrorAlert("No hay productos en el carrito", "Agrega un producto", "info");
      }


   }


   //Function to close the new tab
   const closeTab = () => {
      if (newTab) {
         newTab.close();
         setNewTab(null);
      }
   };

   //Function to request the payment with place to pay (Pasarela de pago of evertech)
   const evertechPaymentPlaceToPay = async (pays) => {
      // We define the variables 
      let secretKey, loginKey, language;
      //let dataOrder = JSON.parse(localStorage.getItem('Detail_order'));
      let machine = JSON.parse(localStorage.getItem('machine'))
      //let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let randomString = generateRandomString(4);
      console.log("Evertech Select====================");
      if (statePayment === false) {
         setStatePayment(true)
         //LoadingAlert()
         try {
            //We map the variables to asing every param value 
            pays.params.forEach(param => {
               switch (param.name) {
                  case "LANGUAGE":
                     language = param.value;
                     break;
                  case "SECRET_KEY":
                     secretKey = param.value;
                     break;
                  case "LOGIN_KEY":
                     loginKey = param.value;
                     break;
                  default:
                     break;
               }
            });
            //We make the new Payment evertech data 
            let branchoffice_id = branchoffice.id
            const evertechResponse = await EvertechFetchAPI.newPayment(branchoffice_id, secretKey, loginKey, language, orderNumber, orderNumber + randomString, `Smartpay order ${orderNumber}`, invoiceTotal, localStorage.getItem('x-access-machine-token'));
            setStatePayment(false)
            //ErrorAlert("", "Todo listo para que realices el pago", "info")
            //We set the request id 
            localStorage.setItem('evertechRequestId', evertechResponse.data.data.requestId);
            //We construct the json to gen the temporal order 
            let json = {
               "client_name": "",
               "localizador": "00",
               "client_phone": "0",
               "ruc": 0,
               "dv": 0,
               "id_Client": 0,
               "dgi_qr": "0",
               "Orden": shoppingcart,
               "Descuento": 0,
               "Total": invoiceTotal,
               "itbms": invoiceTotalItbms,
               "subtotal": invoiceSubtotal,
               "machine_id": machine.id,
               "branchoffice_id": branchoffice.id,
               "tipoOrden": "para llevar",
               "paymethod": 0,
               "is_discount_notification": false,
               "is_cupon": false,
               "is_devolution_notification": false,
               "is_pre_order": false,
               "is_evertech_pay": true,
               "evertech_transaction_id": evertechResponse.data.data.requestId,
               "evertech_code": orderNumber + randomString,
               "reference_evertech_transaction_code": evertechResponse.data.data.reference_evertech_transaction_code
            }
            //We create a pre order 
            await PreOrderFetchAPI.createPreOrder(json, localStorage.getItem('x-access-machine-token'));

            //We check the type of machine 
            if (machine.name_type_machine === "Smart Pay" || machine.name_type_machine === "Vending Machine" || machine.name_type_machine === "Smart pos") {
               //If the machine is a kiosk or a vending we open the modal 



               //setDataHash(evertechResponse.data.data.processUrl)
               //setStatusRequestYappy(true)
               //setQrTypePayment("Evertech")
            } else {
               // We open the checkout page 

               setUrlPlaceToPay(evertechResponse.data.data.processUrl)
               setEnabled(false)

               //setShowModalPlaceTopay(true)
               //openTab(evertechResponse.data.data.processUrl)
               //window.open(evertechResponse.data.data.processUrl, '_blank');
            }

         } catch (err) {
            setSelectedPayment({ payment: "", payment_id: 0 })
            setStatePayment(false)
            setEnabled(true)
            ErrorAlert('', 'No se pudo procesar el pago', 'error')

            console.log(err);
         }
      }
   }

   const payValidUserAndTable = () => {
      if (localStorage.getItem('table') !== null && localStorage.getItem('table') !== undefined && localStorage.getItem('table') !== '') {
         if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
            getTableById('init')
            handlerInit()
         }

         setModalShow(true)
      } else {
         if (localStorage.getItem('client') !== null && localStorage.getItem('client') !== undefined && localStorage.getItem('client') !== '') {

            let client = JSON.parse(localStorage.getItem('client'))
            setClient_name(client.name)
            setId_Client(client.id)
            setModalShow(true)
         } else {
            setModalUser(true)
         }

      }

      setEnabled(true)
   }




   ///===========================USE EFFECT===========================
   useEffect(() => {
      if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
         getTableById('init')
      }
      handlerInit()
   }, [])

   useEffect(() => {
      setHandlerEvent(0)
      lastOrderMachineRequest()
      getOrderNumber()
      getPaymentMethod()
   }, [handlerEvent])

   //Hook of the webhook of evertech transactions place to play 
   useEffect(() => {
      //We verify the machine code 
      if (machine !== undefined && machine !== null && branchoffice !== undefined && branchoffice !== null) {
         // Connection with the sockets server
         const socket = Socket({ "machine_code": machine.code, "branchoffice_code": branchoffice.code });

         //We listen the evertech payment 
         socket.on(`evertechpay`, (data) => {
            let evertechRequestId = localStorage.getItem('evertechRequestId');
            //We check if the evertech request on this dispositive is the same that the socket notify is payed
            if (evertechRequestId == data.requestId) {
               if (data.status.status === 'APPROVED') {
                  //We get the payment reference 
                  let paymentInternalReference = data.payment.map(element => {
                     //We check wich payment was successfull to return the payment reference 
                     if (element.status.status === 'APPROVED') {
                        return element.internalReference
                     }
                  });


                  //format Voucher evertech
                  let datafilter = data
                  if (datafilter.requestId != undefined) {
                     let detailParamsPayment = ""
                     datafilter.payment[0].processorFields.forEach((process) => {
                        detailParamsPayment = detailParamsPayment + `${process.keyword.toUpperCase()}:                ${process.value}\r\n`
                     })

                     data.voucher = `\r\n            ***  EVERTECH  ***             \r\n           ${datafilter.payment[0].issuerName}           \r\nREF:                            ${datafilter.payment[0].reference}\r\nDATE: ${datafilter.status.date.split("T")[0]}               ${datafilter.status.date.split("T")[1]}\r\n\r\nDESCRIPTION:   ${datafilter.request.payment.description}\r\nTOTAL:                         ${datafilter.payment[0].amount.from.currency}. ${datafilter.payment[0].amount.from.total} \r\n            +++ DETAIL PAYMENT +++            \r\n\r\nPAYMENT METHOD:               ${datafilter.payment[0].paymentMethodName.toUpperCase()}\r\n${detailParamsPayment}\r\nSTATUS:                       ${datafilter.status.status}\r\nRECEIPT:                       ${datafilter.payment[0].receipt}\r\n\r\n                +++ PAYER +++                \r\n\r\nNAME:                        ${datafilter.request.payer.name}\r\nEMAIL:                        ${datafilter.request.payer.email}\nPHONE:                        ${datafilter.request.payer.mobile}\n           ****** FIN  ******            \r\n`;

                  }

                  ///---------------------------------------------------------------
                  //We get the dataOrder to add the payment internal reference 
                  //let dataOrder = JSON.parse(localStorage.getItem('Detail_order'))

                  //dataOrder.evertechPaymentReference = paymentInternalReference[0];
                  //dataOrder.EMVStreamResponse = data;
                  //We set the data object on the local storage 
                  //localStorage.setItem('Detail_order', JSON.stringify(dataOrder));

                  //Set the payment status aprroval 
                  closeTab()
                  setPaymentApproval({ status: 1, description: "Registrando ..." });



                  setTimeout(() => {
                     registernewOrder(data, selectedPayment)
                  }, 3000);
               } else {
                  closeTab()
                  //We check if the payment was rejected
                  if (data.status && data.status.status === 'REJECTED') {
                     ErrorAlert('', data.status.message, 'error')
                     setPaymentApproval({ status: 2, description: data.status.message });
                  } else {
                     ErrorAlert('', 'No se pudo procesar el pago', 'error')
                     setPaymentApproval({ status: 2, description: 'No se pudo procesar el pago' });
                  }
                  setSelectedPayment({ payment: "", payment_id: 0 })

                  setTimeout(() => {
                     setShowModalStatusPayment(false)
                     setPaymentApproval({ status: 0, description: "Procesando pago..." })
                     setStatePayment(false)
                     setModalShow(false)
                  }, 3000);



               }
            } else {
               closeTab()
               setPaymentApproval({ status: 2, description: 'No se pudo procesar el pago' });
               setSelectedPayment({ payment: "", payment_id: 0 })
               setTimeout(() => {
                  setShowModalStatusPayment(false)
                  setPaymentApproval({ status: 0, description: "Procesando pago..." })
                  setStatePayment(false)
                  setModalShow(false)
               }, 2000);

               console.log(" respuesta negativa");
            }
         });

         return () => {
            socket.disconnect();
         }

      }

   }, [selectedPayment, statePayment, paymentApproval])
   return (

      <>
         <section className='ecomerce-container-0 d-lg-flex justify-content-lg-center container-lg container-flui'>
            <div className='ecomerce-container-1 position-realtive mx-0 pb-2'>
               <Header></Header>


               <div className='d-flex justify-content-between align-items-center'>


                  <NavLink to={`/home/${business_code}/${branchoffice_code}/${machine_code_table}`}  >
                     <button className='btn btn-outline-secondary rounded-pill   p-0 border-0'>
                        <span className='d-flex align-items-center fw-bold px-3 py-1'> <i class="uil uil-arrow-left icon-header-ecommerce"></i>  Inicio </span>
                     </button>
                  </NavLink>

                  <h5 className='text-white fw-bold text-center m-0'>Mi Orden</h5>
               </div>

               <div className='ecomerce-container-section-0 padding-bottom-shoppingcart   bg-black pt-0 border-top border-secondary'>

                  {(shoppingcart.length + shoppingCartCombos.length) === 0
                     ? <>
                        <span className='mt-0 px-4 mt-5'>
                           <div class="position-relative p-4 text-center text-muted bg-body rounded-4 mx-3">

                              <span className='d-block  icon-sad-lg opacity-25'>
                                 <i class="uil uil-sad-squint"></i>
                              </span>
                              ¡El carrito esta vacío, no
                              contiene productos en este momento!
                           </div>
                        </span>
                     </>
                     : <>

                        {shoppingcart.map((product, index) => (
                           cardProduct(product, index, false)
                        ))}

                        {shoppingCartCombos.map((product, index) => (
                           cardProduct(product, index, false)
                        ))}

                     </>}
               </div>

               <div className=' container-bar-menu total-container position-fixed  bottom-0 start-50 translate-middle-x border py-1 px-3 rounded-4 bg-light '>

                  <div className='d-flex justify-content-between my-2'>
                     <span className='h6 text-danger fw-ligther' onClick={() => clearShoppingcart()}  >
                        Cancelar
                     </span>

                     <BtnAdd />
                  </div>

                  <div className="d-flex justify-content-between ">
                     <div className="d-flex m-1 text-center">
                        <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce  bg-light">
                           Descuento: $
                           <span translate='no' className='fw-bold'>{invoiceTotalDiscount.toFixed(2)}</span>
                        </span>
                     </div>
                     <div className="d-flex m-1 text-center">
                        <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce bg-light">
                           Impuesto: $
                           <span translate='no' className='fw-bold text-center'>{invoiceTotalItbms.toFixed(2)}</span>
                        </span>
                     </div>
                     <div className="d-flex m-1 text-center">
                        <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce bg-light">
                           Subtotal: $
                           <span translate='no' className='fw-bold text-center'>{invoiceSubtotal.toFixed(2)}</span>
                        </span>
                     </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-center total-shopping'>
                     <span className='item-value-product text-dark'>
                        Total
                     </span>
                     <span className='item-value-product text-dark'>
                        $ {invoiceTotal.toFixed(2)}
                     </span>
                  </div>


                  <div className='flex-column'>
                     <div className=' flex-column'>
                        <div className='d-flex justify-content-between align-items-center '>

                           {payments.length > 0 ? <>
                              <button className='btn btn-add-product w-100 p-2 m-1' onClick={() => payValidUserAndTable()}  >
                                 Pagar
                                  $ {invoiceTotal} 
                              </button></> : <>
                              <button className='btn btn-add-product w-100 p-2 m-1' onClick={() => setShowModalConfirmation(true)}  >
                                 Ordernar
                                 $ {invoiceTotal}
                              </button></>}
                           {/*<Toolbar></Toolbar>*/}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Payments modalShow={modalShow}
            setModalShow={setModalShow}
            payments={payments}
            getTableById={getTableById}
            invoiceTotal={invoiceTotal}
            selectedPayment={selectedPayment}
            setSelectedPayment={setSelectedPayment}
            setShowModalConfirmation={setShowModalConfirmation}
            getOrderNumber={getOrderNumber}
            registernewOrder={registernewOrder}
            processPayment={processPayment}
            tableInfo={tableInfo}
            evertechPaymentPlaceToPay={evertechPaymentPlaceToPay}
            enabled={enabled}
            setEnabled={setEnabled}
         />



         <Modal
            show={showModalConfirmation}
            size='lg'
            onHide={() => setShowModalConfirmation(false)}
            centered
         >

            <div className='mt-2 text-center p-1'>
               {table != undefined && table != '' && table != null ? <><div className='mt-5'>
                  <h4 className='fw-bold'>¿Desea ordenar a la {table.is_room == 1 ? <>habitacion</> : <>mesa</>}?</h4>
                  <h1 className='text-center txtRoom mt-3'>{table.name}</h1>
               </div></> : <></>}

            </div>
            <Modal.Body>
               <div className='d-flex justify-content-between justify-content-lg-end mt-2'>
                  <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                     onClick={() => setShowModalConfirmation(false)}> Cancelar </button>




                  <button className='btn bg-success-subtle text-success fw-bold border-0  ms-1'
                     onClick={async () => (getTableById())}> Ordenar </button>
               </div>
            </Modal.Body>
         </Modal>



         <Modal
            show={showModalPlaceTopay}
            size='lg'
            onHide={() => setShowModalPlaceTopay(false)}
            centered
         >

            <div className='mt-2 text-center p-1'><div className='mt-5'>
               <h4 className='fw-bold'>¿Abrir evertech place to pay?</h4>

            </div>

            </div>
            <Modal.Body>
               <div className='d-flex justify-content-between justify-content-lg-end mt-2'>
                  <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                     onClick={() => setShowModalPlaceTopay(false)}> No </button>



                  <button className='btn bg-success-subtle text-success fw-bold border-0  ms-1'
                     onClick={async () => (openTab(urlPlaceToPay))}> si</button>
               </div>
            </Modal.Body>
         </Modal>



         <ModalStatusPayment
            showModalStatusPayment={showModalStatusPayment}
            setShowModalStatusPayment={setShowModalStatusPayment}
            paymentInfo={selectedPayment}
            paymentApproval={paymentApproval}
            invoiceSubtotal={invoiceSubtotal}
            invoiceTotal={invoiceTotal}
            invoiceTotalDiscount={invoiceTotalDiscount}
            invoiceTotalItbms={invoiceTotalItbms}
            invoiceTip={invoiceTip}
         ></ModalStatusPayment>

         <LoginUser modalUser={modalUser} setModalUser={setModalUser} />



      </>

   )
}

export default ShoppingCart