import React, { useState, useEffect } from 'react'
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
import { Tab, Row, Col, Nav } from 'react-bootstrap';
//External modules 
import moment from 'moment-timezone';
//CSS
import './orders.css';
//API
import { NotificationsFetchAPI } from '../../api/Notifications';
//Img
import all_product from '../img/icons/all.png'
//Alerts 
import SuccessAlert from "../Alerts/SuccessAlert"
import ErrorAlert from '../Alerts/ErrorAlert';
import { NavLink, useParams } from 'react-router-dom';
//Notification component
export const Notifications = () => {
    //----------------------------auth params localstorage---------------------------------------------------



    let token = localStorage.getItem("x-access-machine-token")
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
    let business = JSON.parse(localStorage.getItem("business"))
    let machine = JSON.parse(localStorage.getItem('machine'));
    let table = JSON.parse(localStorage.getItem('table'));
    //---------------------------------------------------------------------------------------------------------
    //States
    const [notificationTypes, setNotificationsTypes] = useState([])
    const [notifications, setNotifications] = useState([]);
    const [eventHandler, setEventHandler] = useState(0)
    //Function to get the notifications types by branchoffice
    const getNotificationsTypesByBranchoffice = async () => {
        try {
            //Get the notifications types by branchoffice
            let res = await NotificationsFetchAPI.getNotificationsTypesByBranchoffice(branchoffice.id, token)
            //Set the notifications types
            setNotificationsTypes(res.data.notification_types)
        } catch (err) {
            console.log(err)
            setNotificationsTypes([])
        }
    }
    const { business_code, branchoffice_code, machine_code_table } = useParams();
    //Function to get the notifications by branchoffice and table 
    const getNotifications = async () => {
        try {
            //We get the min and max date
            let minDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')
            let maxDate = minDate + 'T23:59:59.000+00:00'
            //Get the notifications by branchoffice and table
            let res = await NotificationsFetchAPI.getNotifications(branchoffice.code, business.code, table.table_code, minDate + 'T00:00:00.000+00:00', maxDate, token)
            console.log(res)
            setNotifications(res.data.notifications)
        } catch (err) {
            console.log(err)
            setNotifications([])
        }
    }

    //Notification types list 
    const notificationTypesList = () => {
        const list = notificationTypes.map((notificationType, index) => (
            <>
                <div class="col-6 mb-4 mt-2">
                    <div class=" h-100 border rounded-4 p-2 shadow-sm button-press" onClick={() => createNotification({ title: notificationType.name, body: "El cliente solicita " + notificationType.description, machine_id: machine.id, table_id: table.id, branchoffice_code: branchoffice.code, business_code: business.code, notification_type_id: notificationType.notification_id })}>
                        <div class="card-body text-center">
                            {/* <i class="bi bi-laptop fs-1 text-primary mb-3"></i> */}

                            <img src={notificationType.secure_url !== null ? notificationType.secure_url : all_product}
                                className='img-notification-card rounded-4 ' alt='...' />
                            <h3 className='mt-1 title-ecommerce-menu-card  text-center'>{notificationType.name}</h3>
                            {/* <p class="card-text">   {notificationType.description}.</p> */}

                            <div className='d-flex justify-content-center mt-1 opacity-50'>
                                <button type="button" class="btn btn-outline-secondary btn-sm border-0 fw-normal " onClick={() => createNotification({ title: notificationType.name, body: "El cliente solicita " + notificationType.description, machine_id: machine.id, table_id: table.id, branchoffice_code: branchoffice.code, business_code: business.code, notification_type_id: notificationType.notification_id })} >Solicitar</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='col-6 border rounded-4 m-1'>
                <div class="flex-shrink-0">
                    <img src={notificationType.secure_url !== null ? notificationType.secure_url : all_product}
                        className='img-ecommerce-menu-card rounded-3 border' alt='...' />
                </div> 
                <div class="flex-grow-1 ms-3">
                    <div className='mt-2 me-2'>
                        <span className='mt-1 title-ecommerce-menu-card '> {notificationType.name} </span>
                    </div>
                </div>
                <span className='mt-1 d-block description-ecommerce-orders-card '>
                    {notificationType.description}
                </span> 
                <div className='d-flex justify-content-between mt-1'>
                    <button type="button" class="btn btn-primary btn-sm" onClick={() => createNotification({ title: notificationType.name, body: "El cliente solicita " + notificationType.description, machine_id: machine.id, table_id: 12, branchoffice_code: branchoffice.code, business_code: business.code, notification_type_id: notificationType.notification_id })} >Solicitar</button>
                </div>
            </div>  */}

            </>

        ))

        return list
    }

    //Notification list
    const notificationList = () => {
        const list = notifications.map((notification, index) => (
            <div className='ecommerce-orders-card d-flex align-items-center bg-white rounded-3 shadow-sm mt-1 mx-1 p-1'>
                <div className="flex-grow-1 ms-2">
                    <div className='mt-2 me-2'>
                        <span className='mt-1 title-ecommerce-menu-card '> {notification.title} </span>
                    </div>
                    <span className='mt-1 d-block  lead fs-6 text-muted'>
                        {notification.body}
                    </span>
                    <span className='text-right description-ecommerce-orders-card '>
                        Estado: {notification.status === 1
                            ? <span class="badge text-secondary bg-secondary-subtle"><i class="uil uil-spinner-alt"></i>Pendiente</span>
                            : notification.status === 2
                                ? <span class="badge text-success bg-success-subtle"><i class="uil uil-check-circle"></i>Atendida</span>
                                : <span class="badge text-danger bg-danger-subtle"><i class="uil uil-multiply"></i>Rechazado</span>
                        }
                    </span>
                </div>
            </div>
        ))

        return list
    }


    //Function to create a notification
    const createNotification = async (data) => {
        try {
            //Create the notification
            let res = await NotificationsFetchAPI.createNotification(data, token)
            console.log(res)
            //Show alert
            SuccessAlert("Notificación enviada");
            setEventHandler(1)
        } catch (err) {
            console.log(err)
            //We check if the error is managed by the API
            if (err.response.data.error !== undefined) {
                //Show alert
                ErrorAlert(err.response.data.error, "Advertencia", "warning")
            } else {
                //Show alert
                ErrorAlert("Error al enviar la notificación", "Intente de nuevo", "error")
            }
        }
    }

    const [selectedTab, setSelectedTab] = useState("catogire1");

    const handleTabChange = (event) => {
        setSelectedTab(event.target.id);
    };




    //Hook to get the notifications types by branchoffice
    useEffect(() => {
        getNotificationsTypesByBranchoffice()
        getNotifications();
        setEventHandler(0)
    }, [eventHandler]);

    return (
        <>


            <section className='ecomerce-container-0 d-lg-flex justify-content-lg-center container-lg'>
                <div className='ecomerce-container-1 position-realtive pb-2'>
                    <Header></Header>


                    <div className='d-flex justify-content-between align-items-center'>
                        <NavLink to={`/home/${business_code}/${branchoffice_code}/${machine_code_table}`}  >
                            <button className='btn btn-outline-secondary rounded-pill   p-0 border-0'>
                                <span className='d-flex align-items-center fw-bold px-3 py-1'> <i class="uil uil-arrow-left icon-header-ecommerce"></i>  Inicio </span>
                            </button>
                        </NavLink>

                        <h5 className='text-white fw-bold text-center m-0'>Notificaciones</h5>
                    </div>

                    <div className="container-list-categories d-flex overflow-auto py-2">
                        {/* 1 */}
                        <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire1">

                            <input name="categories" class="radio-categories" type="radio"
                                id="catogire1"
                                checked={selectedTab === "catogire1"}
                                onChange={handleTabChange}
                            />

                            <span className="plan-details-categories-input">
                                <span className="plan-type-radio-categories">   <i class="uil uil-bell"></i>     Notificaciones  </span>
                            </span>
                        </label>
                        {/* 2 */}
                        <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire2">

                            <input name="categories" class="radio-categories" type="radio"
                                id="catogire2"

                                checked={selectedTab === "catogire2"}
                                onChange={handleTabChange}
                            />

                            <span className="plan-details-categories-input">
                                <span className="plan-type-radio-categories"> <i class="uil uil-check"></i>      Realizadas  </span>
                            </span>
                        </label>
                    </div>


                    <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 container pb-5'>



                        {/* <Tab.Container defaultActiveKey="notifiaction_types"> */}
{/* 
                            <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                <li className="nav-sp-item">
                                    <Nav.Link eventKey="notifiaction_types" title="Tipos de notificaciones" ><i class="uil uil-bell"></i>Notificaciones</Nav.Link>
                                </li>
                                <li className="nav-sp-item"  >
                                    <Nav.Link eventKey="client_notifications" ><i class="uil uil-bell-school"></i>Realizadas</Nav.Link>
                                </li>
                            </Nav> */}

                            {/* Contenido basado en el tab seleccionado */}
                            <div className="tab-content">
                                {selectedTab === "catogire1" && (
                                    <div className=' row'>
                                        {notificationTypesList()}
                                    </div>
                                )}
                                {selectedTab === "catogire2" && (
                                        <div className="flex-grow-1 ms-2">
                                        {notificationList()}
                                    </div>
                                )}
                            </div>

                            {/* <Tab.Content>
                                <Tab.Pane eventKey="notifiaction_types">

                                    

                                </Tab.Pane>
                                <Tab.Pane eventKey="client_notifications">

                                

                                </Tab.Pane>
                            </Tab.Content> */}


                        {/* </Tab.Container> */}
                    </div>

                </div>
                {/* <BarMenu></BarMenu> */}
            </section>
        </>
    )
}
