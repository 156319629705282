import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import RegisterUser from './RegisterUser';
import { ClientsFetchAPI } from '../../api/Clients';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { TablesFetchAPI } from '../../api/Tables';
import Select from 'react-select';
const LoginUser = ({ modalUser, setModalUser }) => {
   const [modalUserRegister, setModalUserRegister] = useState(false)
   const [nickNamel, setNickName] = useState('')
   const [password, setPassword] = useState('')
   const [selectTable, setSelectTable] = useState({})
   const [tables, setTables] = useState([]);

   const cleanInputs = () => {
      setNickName('')
      setPassword('')
      setModalUser(false)
   }

   const loginUser = async () => {
      LoadingAlert('Iniciando sesión')
      let token = localStorage.getItem('x-access-machine-token')
      let business = JSON.parse(localStorage.getItem('business'))
      let data = {
         "nickname": nickNamel,
         "pass": password,
         "business_id": business.id
      }
      try {
         const response = await ClientsFetchAPI.loginClientForBusiness(data, token)
         ErrorAlert('', 'Usuario logueado con exito', 'success')
         let client = response.data.client

         console.log(selectTable);
         localStorage.setItem('client', JSON.stringify(client))
         localStorage.setItem('table', JSON.stringify(selectTable))
         localStorage.setItem('codeRoom', selectTable.password)
         cleanInputs()
         updatePage();

      } catch (err) {
         ErrorAlert('', 'Error al loguear usuario', 'error')
         console.log(err);
      }
   }
   const updatePage = () => {
      window.location.reload()
   }

   const handleSaveTableSelect = (option) => {
      setSelectTable(option.value);
   }

   const getTablesByBusiness = async (businessinfo_id, token) => {
      try {
         const response = await TablesFetchAPI.getBusinessTables(businessinfo_id, token);

         //ROOOM SERVICE
         let tablesInfo = []
         response.data.tables.forEach(table => {
            if (table.status === 1) { tablesInfo.push({ value: table, label: table.name }) }
         });

         setTables(tablesInfo)
         /*localStorage.setItem('table', JSON.stringify(tablesInfo.value))
         localStorage.setItem('codeRoom', tablesInfo.value.password)*/

      } catch (err) {
         console.log(err);
      }
   }


   useEffect(() => {
      getTablesByBusiness(JSON.parse(localStorage.getItem('business')).id, localStorage.getItem('x-access-machine-token'))
   }, [])

   return (
      <>
         <Modal
            show={modalUser}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
         >

            <Modal.Body>
               {/* <div className='header-profile-banner-login position-relative'>
                  <img className='header-profile-img'
                     src="https://images.unsplash.com/photo-1466978913421-dad2ebd01d17?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />

                  <div className='header-profile-text-banner position-absolute '>
                     <div className=' text-center mt-5 text-white fw-bold'>
                        ¡Ingresa ahora!
                     </div>
                  </div> 
               </div> */}
               <div className='position-absolute '>
                  <div className='fw-bold badge rounded-2 text-bg-success'>
                     <i class="uil uil-chat-bubble-user"></i>  Usuario
                  </div>
               </div>

               <h2 className={'kiosk-delivery_title text-center text-success '}>
                  Inicio de Sesión
               </h2>
               <div className='d-flex align-items-center justify-content-center'>
                  <div className="text-dark rounded-4 position-relative text-wrap bg-white   p-2">
                     <div className='d-flex'>
                        <Form>
                           <Form.Group className="mb-3 kiosk-text-card" controlId="exampleForm.ControlInput1">
                              <span className="fw-bold">Ingresa nombre de usuario </span>
                              <Form.Control className='kiosk-text-card border' type="text" placeholder="email@example.com" onChange={(e) => setNickName(e.target.value)} />
                           </Form.Group>
                           <Form.Group className="mb-3 kiosk-text-card " controlId="exampleForm.ControlInput1">
                              <span className="fw-bold">Ingresa la contraseña</span>
                              <Form.Control className='kiosk-text-card border' type="password" placeholder="*********" onChange={(e) => setPassword(e.target.value)} />
                           </Form.Group>

                           <Form.Group className="mb-3" controlId="clientId">
                              <Form.Label>Selecciona el stan o mesa</Form.Label>
                              <Select
                                 className='mb-3 kiosk-text-card '
                                 options={tables}
                                 onChange={handleSaveTableSelect}
                              />
                           </Form.Group>
                        </Form>
                     </div>



                  </div>
               </div>

               <div className='d-flex justify-content-between '>
                  <button className={`m-1 btn btn-danger rounded btn-sm kiosk-subtitle-md position-relative px-4 `} onClick={() => (cleanInputs())}>
                     Cancelar
                  </button>
                  <button className={`m-1 btn btn-primary rounded btn-sm kiosk-subtitle-md position-relative px-4 `} onClick={() => loginUser()}>
                     Ingresar
                  </button>
               </div>

               <Button variant={'outline-primary'} className='mt-2 w-100' onClick={() => setModalUserRegister(true)}>Registrar</Button>



            </Modal.Body>
            {/* <Modal.Footer>
               <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
         </Modal>

         <RegisterUser modalUserRegister={modalUserRegister} setModalUserRegister={setModalUserRegister}></RegisterUser>
      </>
   )
}

export default LoginUser