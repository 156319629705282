import React from 'react'
import "./banner.css";


//img 
import default_logo from './img/logoDefaultRST.png'
import rest_0 from './img/rest-0.svg'
import rest_1 from './img/rest-3.svg'
import rest_2 from './img/rest-2.svg'





const BannerBrand = ({ nameBussines, nameBranch }) => {

    let business = "";
    let branchoffice = "";


    let logo = localStorage.getItem('logo');
    return (
        <>
            <section className='banner-brand mb-4'>
                <div className='flex-column  px-2'>

                    <div className="d-flex justify-content-center">
                        <img src={logo} alt="" className="banner-brand-logo" />
                    </div>
                    <div className='banner-content rounded-3 py-2'>

                        <h3 className='banner-subtitle opacity-75'>¡Bienvenido a
                            <span className='brand-name fw-bold'>  {nameBranch} </span>
                            !</h3>
                        <h2 className='banner-slogan'>
                            Un
                            <span className='brand-name'> plato especial </span>
                            esta esperando por ti
                        </h2>
                        {/* <p className='banner-description  text-muted'>En nuestra aplicacion podras encontrar tus platos favoritos</p> */}
                    </div>
                </div>

                {/* <div class="container text-center text-white fw-bold">
                    <div class="row g-2 ">
                        <div class="col-5 ">
                            <div class="banner-feat p-3  rounded-4 h-100 banner-red">

                                <img src={rest_0} alt="" className="banner-feat-img" />
                                <span className="text-center">

                                    Restaurantes
                                </span>


                            </div>
                        </div>
                        <div class="col-7">
                            <div class="banner-feat p-3  rounded-4 h-100 banner-yellow">
                                Promociones
                                <img src={rest_1} alt="" className="banner-feat-img" />
                            </div>
                        </div>
                        <div class="col-12">

                            <div class="banner-feat p-3  rounded-4 banner-blue">
                                <img src={rest_2} alt="" className="banner-feat-img" />
                                Nuevos Platillos </div>
                        </div>
                    </div>
                </div> */}

            </section>


        </>
    )
}

export default BannerBrand