import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import {Button} from 'react-bootstrap';
import { LanguagesFetchAPI } from '../../../api/Languages'
import LoadingAlert from '../../Alerts/LoadingAlert';



function ModalLanguage() {
    const [showModalLanguage, setShowModalLanguage] = useState(false);
    const [statusLanguage, setStatusLanguage] = useState(0)
    let token = localStorage.getItem("x-access-machine-token")
    const [languages, setLanguages] = useState([])

   
    //get languages 
    const getLanguages = async () => {
        try {
            const response = await LanguagesFetchAPI.getLanguages(token);
            setLanguages(response.data.languages)
        } catch (err) {
            console.log(err);
        }
    }

    //change Langage
    const setLanguage = (language) => {
        localStorage.setItem("lang", language);

        LoadingAlert("Cambiando idioma...", "Espere un momento por favor")
        setTimeout(() => {
            window.location.reload(true);
        }, 3000);
        /*if (statusLanguage == 0) {
            setStatusLanguage(1);
            document.cookie = "googtrans=/" + language;
            new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')
            setShowModalLanguage(false)
        } else {
            LoadingAlert("Restaurando idioma...", "Espere un momento por favor")
            setTimeout(() => {
                window.location.reload(true);
            }, 3000);
        }*/
    }

    const changeLanguage = () => {
        if (localStorage.getItem("lang") != "" && localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined && localStorage.getItem("lang") != 	"es") {
            document.cookie = "googtrans=/" + localStorage.getItem("lang");
            new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')
        }
    }

    {/*view languages*/ }
    const viewLanguages = () => {

        const listItems = languages.map((language) => (
            <>
                <div className='m-3'>
                    <div className='btn btn-outline-primary  d-block border border-3 rounded-pill p-3 ' onClick={() => setLanguage(language.code)}>
                        <span className='p-3  text-wrap px-3'>{language.name}</span>
                    </div>
                </div>
            </>
        ));
        return (listItems)
    }

    useEffect(() => {
        getLanguages()
        changeLanguage()
    }, []);
    return (
        <>
            <button className='btn btn-header-ecommerce  btn-sm rounded-4' onClick={() => (setShowModalLanguage(true))}>
                <i className="uil uil-english-to-chinese  "></i>
            </button>


            <Modal
                centered
                className='modal-dialog-kk'
                size="lg"
                show={showModalLanguage}
                onHide={() => setShowModalLanguage(false)}>
                <Modal.Body   >

                    <section className=' rounded-4' >
                        
                        <div className=' scale-up-top mt-5'>
                            <h2 className=' text-center'>
                            <i className="uil uil-english-to-chinese ms-1 text-secondary"></i>  Elige tu idioma preferido:
                            </h2>

                            {viewLanguages()}

                        </div>
                        <div className='text-muted text-center'>
                            <i className="uil uil-info-circle me-2"></i>
                            Selecciona el idioma que prefieras. ¡Gracias!
                        </div>
                    </section>

                    <div className='d-flex justify-content-center m-2'>
                        <Button
                            onClick={() => (setShowModalLanguage(false))}
                            type="button"
                            variant="danger"
                        ><i class="uil uil-times-circle"></i> Cerrar
                        </Button>
                    </div>
                </Modal.Body>



            </Modal>
        </>
    )
}

export default ModalLanguage