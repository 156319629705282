import React, { useState, useEffect } from 'react'
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
import { Button } from 'react-bootstrap';
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
//css
import './profile.css'
import LoginStand from './LoginStand'
import LoginUser from './LoginUser'
import { TablesFetchAPI } from '../../api/Tables';
import { OrdersFetchAPI } from '../../api/Orders';


//img
import ImgProfile from '../../assets/img/logos/profile-login.png';
import { RenderIf } from '../common/utils/RenderIf';
export const Porfile = () => {
  const navigate = useNavigate();
  const { business_code, branchoffice_code, machine_code_table } = useParams();
  const [modalUser, setModalUser] = React.useState(false);
  const [modalStand, setModalStand] = React.useState(false);
  let client = { name: "", phone: "", nickname: "" }
  let table = { name: "", client_name: "", is_room: "" }

  const [clientInfo, setClientInfo] = useState(() => {
    if (localStorage.getItem('client') != null && localStorage.getItem('client') != undefined && localStorage.getItem('client') != '') {
      return JSON.parse(localStorage.getItem('client'))
    } else {
      return 0
    }
  })
  const [tableInfo, setTableInfo] = useState(() => {
    if (localStorage.getItem('table') != null && localStorage.getItem('table') != undefined && localStorage.getItem('table') != '') {
      return JSON.parse(localStorage.getItem('table'))
    } else {
      return 0
    }
  })

  if (localStorage.getItem('table') != undefined && localStorage.getItem('table') != '' && localStorage.getItem('table') != null) {
    let dataTable = JSON.parse(localStorage.getItem('table'))

    table = dataTable
  }

  const updatePage = () => {
    window.location.reload()
  }

  //================================================================================================
  const getTableById = async () => {
    let token = localStorage.getItem('x-access-machine-token');
    let business = JSON.parse(localStorage.getItem('business'))
    let dataTable = JSON.parse(localStorage.getItem('table'))
    try {

      const response = await TablesFetchAPI.getTableById(dataTable.id, token);
      let tableNow = response.data.table

      let codeRoom = localStorage.getItem('codeRoom')
      if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
        if (codeRoom != tableNow.password) {
          navigate(`/branch/${business_code}/${dataTable.table_code}`)
        }

      } else {
        navigate(`/branch/${business_code}/${dataTable.table_code}`)
      }
      //----------------------------------------------
    } catch (err) {
      navigate(`/branch/${business_code}/${dataTable.table_code}`)
      console.log(err);
    }

  }

  const out = () => {
    localStorage.removeItem('client')
    navigate(`/home/${business_code}/${branchoffice_code}/${machine_code_table}`)
  }


  const [selectedTab, setSelectedTab] = useState("catogire1");

  const handleTabChange = (event) => {
    setSelectedTab(event.target.id);
  }


  useEffect(() => {
    if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
      getTableById()
    }
  }, [])

  return (
    <>
      <section className='ecomerce-container-0 d-lg-flex justify-content-lg-center container-lg container-flui'>
        <div className='ecomerce-container-1 position-relative'>
          <Header></Header>

          {/* Si no se han logeado sale este  */}
          {/* <div className='ecomerce-container-section-0'>
            <div className='d-flex justify-content-center'>
              <img className='header-profile-img-logo'
                src={ImgProfile} alt="" />
            </div>

            <div class="my-5 mx-4">
              <div class="d-grid gap-2">
                <span className='fw-bold  text-center  h5' >Ingresa a tu cuenta </span>
                <button class="btn btn-success fw-bold  " type="button" onClick={() => setModalUser(true)}>
                  <i class="uil uil-user-circle me-2"></i>
                  Iniciar Sesión
                </button>
                <span className='fw-bold h5 text-center mt-1'>O </span>
                <button class="btn btn-primary fw-bold" type="button" onClick={() => setModalStand(true)}>
                  <i class="uil uil-web-grid-alt me-2"></i>
                  Ingresar como Stand
                </button>
              </div>
            </div>
          </div> */}

          <div className='d-flex justify-content-between align-items-center'>
            <NavLink to={`/home/${business_code}/${branchoffice_code}/${machine_code_table}`}  >
              <button className='btn btn-outline-secondary rounded-pill   p-0 border-0'>
                <span className='d-flex align-items-center fw-bold px-3 py-1'> <i class="uil uil-arrow-left icon-header-ecommerce"></i>  Inicio </span>
              </button>
            </NavLink>

            <h5 className='text-white fw-bold text-center m-0'>Mi Perfil</h5>
          </div>

          {/* Tabs  */}
          <div className="container-list-categories d-flex overflow-auto py-2">
            {/* 1 */}
            <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire1">

              <input name="categories" class="radio-categories" type="radio"
                id="catogire1"
                checked={selectedTab === "catogire1"}
                onChange={handleTabChange}
              />

              <span className="plan-details-categories-input">
                <span className="plan-type-radio-categories">  <i class="uil uil-user"></i> Detalles de Usuario  </span>
              </span>
            </label>
            {/* 2 */}
            <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire2">

              <input name="categories" class="radio-categories" type="radio"
                id="catogire2"

                checked={selectedTab === "catogire2"}
                onChange={handleTabChange}
              />

              <span className="plan-details-categories-input">
                <span className="plan-type-radio-categories"><i class="uil uil-check-circle"></i> Historial de Ordenes   </span>
              </span>
            </label>
          </div>

          <div className='ecomerce-container-section-0  border rounded-4 bg-white pt-0 position-relative'>
            {/* Contenido basado en el tab seleccionado */}
            <div className="tab-content">
              {selectedTab === "catogire1" && (
                <>

                  {/* Si  se loguearon entonces saldra esto   */}
                  <div className='ecomerce-container-section-0  px-2 border-top'>
                    {/* <div className='bg-header-profile p-1'>
                      <small>Hello </small>
                      <h3>{table.client_name == null ? <>*****</> : <>{table.client_name} </>}  </h3>
                    </div> */}
                    <div class="d-flex flex-column py-2 ">
                      <h6 className='fw-bold text-center'>Datos de usuario</h6>
                      <div class="list-group">
                        {tableInfo != 0 ? <>
                          <div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                            <div className='flex-shrink-0 '>
                              {/*<i class="uil uil-envelope bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                              <i class="uil uil-bed bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                            </div>

                            <div class="d-flex gap-2 w-100 justify-content-between">
                              <div>
                                <h6 class="mb-0">{table.is_room == 1 ? <>Habitacion</> : <>Mesa</>}</h6>
                                <p class="mb-0 opacity-75">{table.name}</p>
                              </div>

                            </div>
                          </div>



                          <div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                            <div className='flex-shrink-0'>
                              {/*<i class="uil uil-phone bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}
                              <i class="uil uil-envelope bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                            </div>
                            <div class="d-flex gap-2 w-100 justify-content-between">
                              <div>
                                <h6 class="mb-0">Correo:</h6>
                                <p class="mb-0 opacity-75">example@email.com</p>
                              </div>

                            </div>
                          </div>


                          {clientInfo != 0 ? <>
                            <div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                              <div className='flex-shrink-0 '>
                                {/*<i class="uil uil-envelope bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                                <i class="uil uil-user-circle bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                              </div>

                              <div class="d-flex gap-2 w-100 justify-content-between">
                                <div>
                                  <h6 class="mb-0">Nombre</h6>
                                  <p class="mb-0 opacity-75">{clientInfo.name}</p>
                                </div>

                              </div>
                            </div>


                            <div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                              <div className='flex-shrink-0'>
                                {/*<i class="uil uil-phone bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                                <i class="uil uil-user-circle bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                              </div>
                              <div class="d-flex gap-2 w-100 justify-content-between">
                                <div>
                                  <h6 class="mb-0">Nombre de usuario:</h6>
                                  <p class="mb-0 opacity-75">{clientInfo.nickname}</p>
                                </div>

                              </div>
                            </div>


                            {clientInfo.ruc != null && clientInfo.dv != null ? <>
                              <div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                                <div className='flex-shrink-0'>
                                  {/*<i class="uil uil-phone bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                                  <i class="uil uil-user-circle bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                                </div>
                                <div class="d-flex gap-2 w-100 justify-content-between">
                                  <div>
                                    <h6 class="mb-0">RUC / DV:</h6>
                                    <p class="mb-0 opacity-75">{clientInfo.ruc} / DV: {clientInfo.dv}</p>
                                  </div>

                                </div>
                              </div></> : <></>}

                            <div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                              <div className='flex-shrink-0'>
                                {/*<i class="uil uil-phone bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}
                                <i class="uil uil-label bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                              </div>
                              <div class="d-flex gap-2 w-100 justify-content-between">
                                <div>
                                  <h6 class="mb-0">Codigo de usuario:</h6>
                                  <p class="mb-0 opacity-75">{clientInfo.user_code}</p>
                                </div>

                              </div>
                            </div>
                          </> : <><div class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                            <div className='flex-shrink-0'>
                              {/*<i class="uil uil-phone bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                              <i class="uil uil-user-circle bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                            </div>
                            <div class="d-flex gap-2 w-100 justify-content-between">
                              <div>
                                <h6 class="mb-0">Nombre:</h6>
                                <p class="mb-0 opacity-75">{table.client_name == null || table.client_name == undefined ? <>No hay cliente relacionado</> : <>{table.client_name} </>}</p>
                              </div>

                            </div>
                          </div>
                          </>}</> : <>



                        </>}

                        {/* 
                <div className=' d-flex  justify-content-center'>
                  <span className='fw-bold text-danger'>
                    <i class="uil uil-signout"></i>
                    Cerrar Sesión
                  </span>

                </div>*/}

                      </div>
                    </div>
                  </div>
                  {/* <button onClick={() => setModalStand(true)}>Login stan</button> */}
                </>
              )}
            </div>

          </div>


          {clientInfo != 0 ? <><Button variant='warning' onClick={() => out()}><i class="uil uil-sign-out-alt"> Cerrar sesion</i></Button></> : <><Button onClick={() => setModalUser(true)}><i class="uil uil-user-circle"> Iniciar sesion</i></Button></>}


          <div className='py-1'>
            <div className='text-center opacity-25'>
              <small className='text-white '> Copyright ©2025 All rights reserved
                by Smart Pay</small>
            </div>
          </div>



        </div>
      </section>


      {/*<LoginStand show={modalStand} onHide={() => setModalStand(false)} />*/}


      <LoginUser modalUser={modalUser} setModalUser={setModalUser} />


    </>
  )
}
