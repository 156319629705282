import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';
import loading from '../img/loading.gif';
import checkimg from '../img/check.png'; 
import cancel from '../img/cancel.png';
import { Button } from 'react-bootstrap';

// ...existing code...
export const ModalStatusPayment = ({showModalStatusPayment, setShowModalStatusPayment, paymentInfo, paymentApproval, invoiceSubtotal, invoiceTotal, invoiceTotalDiscount, invoiceTotalItbms, invoiceTip}) => {
    

    return (
        <>
            <Modal
                centered
                className='modal-dialog-kk'
                fullscreen
                show={showModalStatusPayment}
                onHide={() => setShowModalStatusPayment(false)}>

                <Modal.Body className='p-0'>

                <>
            <section className='kiosk-delivery justify-content-center align-items-center' >
                <div>
                   {/*} <ImgHeader
                        classContainer={"kiosk-delivery_header scale-up-horizontal-left mt-5"}
                        classImg={"kiosk-delivery_header_img"}>
                    </ImgHeader>*/}

                    <div className='mt-3'>
                        <div className='p-lg-5 p-4 border border-light border-3 rounded-5 mx-lg-5 mx-md-5 mx-1 shadow-lg' >


                            <main className='d-flex justify-content-center align-items-center h-100 '>
                                <section className='p-4 w-100 position-relative m-3'>
                                    <div className='sections-messages  '>
                                        {/* Process Loading Payment  */}

                                        <RenderIf isTrue={paymentApproval.status == 0}>
                                            <div className='px-4 pb-1  text-center  msg-pay mt-3'>
                                                <div className='msg-position'>
                                                    <div className='d-flex justify-content-center'>
                                                        <img className='mb-4 loading-status-pay' src={loading} />
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="position-absolute top-0 start-50
                         translate-middle badge rounded-pill bg-primary kiosk-subtitle-md">
                                                Método de Pago: {paymentInfo.payment}
                                            </span>

                                            <div className='d-block'>
                                                <h2 className="display-6 fw-bold kiosk-subtitle-md text-muted text-center d-block">
                                                    {paymentApproval.description}
                                                </h2>
                                            </div>

                                           
                                        </RenderIf>
                                        <RenderIf isTrue={paymentApproval.status == 1}>
                                            <div className='px-4 pb-1 mt-5 text-center '>
                                                <img className='mx-auto mb-4 loading-status-pay' src={checkimg} />
                                                <h2 className="display-6 fw-bold kiosk-subtitle-md">
                                                    ¡Éxito!
                                                </h2>
                                                <p className='kiosk-subtitle-md'> Su pago ha sido procesado correctamente.</p>
                                            </div>
                                            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success kiosk-subtitle-md">
                                                Método de Pago: {paymentInfo.payment}
                                            </span>
                                        </RenderIf>
                                        {/* Fail Payment  */}
                                        <RenderIf isTrue={paymentApproval.status == 2}>
                                            <div className='px-4 pb-1 mt-5 text-center  '>
                                                <img className='mx-auto mb-4 loading-status-pay' src={cancel} />
                                                <h2 className="fw-bold kiosk-subtitle-md">
                                                    {paymentApproval.description}
                                                </h2>
                                                <p className='kiosk-subtitle-md'> Por favor, verifique la información e intente nuevamente.</p>
                                            </div>
                                            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger kiosk-subtitle-md">
                                                Método de Pago: {paymentInfo.payment}
                                            </span>

                                        </RenderIf>

                                        <RenderIf isTrue={paymentApproval.status == 3}>
                                            <div className='px-4 pb-1 mt-5 text-center msg-pay'>
                                                <img className='mx-auto mb-4 loading-status-pay ' src={cancel} />
                                                <h2 className="fw-bold kiosk-subtitle-md">
                                                    {paymentApproval.description}
                                                </h2>
                                                <p className='kiosk-subtitle-md'>Por favor, verifique la información e intente nuevamente.</p>
                                            </div>
                                            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger kiosk-subtitle-md">
                                                Método de Pago: {paymentInfo.payment}
                                            </span>

                                            <div className='d-flex justify-content-center alight-item-center m-2'>
                                                <Button className='m-2' >Volver</Button>
                                                <Button className='m-2 ' > Intentar de nuevo</Button>
                                            </div>

                                        </RenderIf>
                                    </div>

                                    {/* <div className="px-4 pb-1 mt-5 text-center">
<img className="mx-auto mb-4 bg-secondary rounded " src={paymentInfo.img} alt="" width="72" height="57" />
<h2 className="display-6 fw-bold">{paymentApproval.description}</h2>
</div> */}
                                </section>

                            </main>


                            {/*Detalle del desgloce*/}
                            <div className=''>
                                <div className="d-flex justify-content-between my-3">
                                    <small className="text-muted kiosk-subtitle-md fw-light"> Descuento:</small>
                                    <span className="text-muted  kiosk-subtitle-md  " translate='no'>
                                        <span className="px-2 fw-light">- $</span>
                                        <span className='fw-light'>{invoiceTotalDiscount.toFixed(2)}</span>
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between my-3 ">
                                    <small className="text-muted  kiosk-subtitle-md  fw-light"> Subtotal: </small>
                                    <span className="text-muted fw-light  kiosk-subtitle-md " translate='no'>
                                        <span className="px-2  fw-light ">$</span>{invoiceSubtotal.toFixed(2)}</span>
                                </div>
                                <div className="d-flex justify-content-between my-3">
                                    <small className=" text-muted  kiosk-subtitle-md  fw-light"> Itbms:
                                    </small>
                                    <span className="text-muted fw-light kiosk-subtitle-md" translate='no'>
                                        <span className="px-2 fw-light ">$</span>{invoiceTotalItbms.toFixed(2)}</span>
                                </div>




                                <div className="d-flex justify-content-between my-3 total-shopping">
                                    <strong className="text-black fw-bold  mt-1 kiosk-subtitle-md "> Total <small>(+Itbms)</small>: </strong>
                                    <span className="text-black kiosk-subtitle-md " translate='no'>
                                        <span className="px-2 ">$
                                        </span>
                                        <strong>{invoiceTotal.toFixed(2)}</strong>
                                    </span>
                                </div>

                                {invoiceTip > 0 ? <>
                                    <div className="d-flex justify-content-between my-3">
                                        <small className=" text-muted  kiosk-subtitle-md  fw-light"> Propina:
                                        </small>
                                        <span className="text-muted fw-light kiosk-subtitle-md" translate='no'>
                                            <span className="px-2 fw-light ">$</span>{invoiceTip.toFixed(2)}</span>
                                    </div>
                                </> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            
        </>

                    
                </Modal.Body>
            </Modal>
        </>)
}

export default ModalStatusPayment