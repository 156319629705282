import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import './header.css'
import Lenguage from '../Lenguage/Lenguage';
import { TablesFetchAPI } from '../../../api/Tables';


//Components

import RestaurantModal from '../../Restaurant/RestaurantModal'

//Utils 
import { RenderIf } from '../utils/RenderIf';
import ModalLanguage from './ModalLanguage';
export const Header = ({ type, shoppingcart, shoppingCartCombos }) => {
   const navigate = useNavigate();

   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [businessCode, setbusinessCode] = useState(business_code);
   const [modalShow, setModalShow] = React.useState(false);
   let logo = localStorage.getItem('logo')
   let brachoffice_name = ''
   let codeTable = ''

   if (localStorage.getItem("branchoffice") != "" && localStorage.getItem("branchoffice") != undefined) {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      brachoffice_name = branchoffice.name
      console.log(branchoffice);

   }

   if (localStorage.getItem('table') != undefined && localStorage.getItem('table') != '' && localStorage.getItem('table') != null) {
      let dataTable = JSON.parse(localStorage.getItem('table'))
      codeTable = dataTable.table_code

   }

   //================================================================================================
   const getTableById = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {

         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table

         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            }

         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }
   }


   let countProduct = 0
   if (shoppingcart != undefined) {

      shoppingcart.forEach((product) => {
         countProduct = (countProduct + parseInt(product.amount))
      });

   }

   if (shoppingCartCombos != undefined) {
      shoppingCartCombos.forEach((product) => {
         countProduct = (countProduct + parseInt(product.amount))
      });
   }


   useEffect(() => {
      if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
         getTableById()
      }
   }, [])
   return (
      <>
         <header className='bg-header text-center fw-bold'>
            <div className='d-flex justify-content-between  align-items-center'>
               {/* <div>
                  <img src={logo}
                     alt="Logo Bussiness" className='img-ecommerce-header rounded-3' />
               </div> */}

              
                  {codeTable == '' ? <><NavLink to={`/branch/${businessCode}/select/${true}`}> <RestaurantModal brachoffice_name={brachoffice_name} /></NavLink></> : <><NavLink  to={`/branch/${businessCode}/${codeTable}/select/${true}`}> <RestaurantModal brachoffice_name={brachoffice_name} /></NavLink></>}
               
               {type === 'restaurant' ? <><ModalLanguage></ModalLanguage></> : <>
                  <div className='d-flex justify-content-between gap-1'>

                     {/* 
                     {codeTable == '' ? <>
                        <NavLink
                           to={`/branch/${businessCode}/select/${true}`}
                        >
                           <button className='btn btn-header-ecommerce btn-sm rounded-4'>
                              <i className="uil uil-store icon-header-ecommerce"></i>
                           </button>
                        </NavLink></> : <>
                         <NavLink
                           to={`/branch/${businessCode}/${codeTable}/select/${true}`}
                        >
                           <button className='btn btn-header-ecommerce btn-sm rounded-4'>
                              <i className="uil uil-store icon-header-ecommerce"></i>
                           </button>
                        </NavLink> 
                        
                        
                        </> } */}




                     {/* <button className='btn btn-header-ecommerce btn-sm rounded-4'>
                        <i className="uil uil-ticket icon-header-ecommerce"></i>
                     </button> */}


                     {/** Orders button */}
                     <NavLink
                        to={`/orders/${businessCode}/${branchoffice_code}/${machine_code_table}`}>
                        <button className='btn btn-header-ecommerce  btn-sm rounded-4'>
                           <i className="uil uil-receipt icon-header-ecommerce"></i>
                        </button>
                     </NavLink>

                     {/** Notification button */}
                     <RenderIf isTrue={branchoffice_code !== undefined && machine_code_table !== undefined}>
                        <NavLink
                           to={`/notifications/${businessCode}/${branchoffice_code}/${machine_code_table}`}>
                           <button className='btn btn-header-ecommerce  btn-sm rounded-4'>
                              <i className="uil uil-bell icon-header-ecommerce"></i>
                           </button>
                        </NavLink>
                     </RenderIf>


                     {/** Shopping Cart button */}
                     <NavLink
                        to={`/cart/${businessCode}/${branchoffice_code}/${machine_code_table}`}
                        className={'position-relative'}
                     >
                        <button className='btn btn-header-ecommerce  btn-sm rounded-4'>
                           <i className="uil uil-shopping-cart icon-header-ecommerce"></i>
                        </button>


                        {
                           countProduct > 0
                              ? <>
                                 <span class="position-absolute  top-0 start-50 translate-middle  badge rounded-pill text-bg-primary px-2 py-1">
                                    {countProduct}
                                 </span>
                              </>
                              : <>
                              </>
                        }

                     </NavLink>


                     {/** Profile button */}
                     <NavLink
                        to={`/profile/${businessCode}/${branchoffice_code}/${machine_code_table}`}>
                        <button className='btn btn-header-ecommerce  btn-sm rounded-4'>
                           <i className="uil uil-user icon-header-ecommerce"></i>
                        </button>
                     </NavLink>

                     <ModalLanguage></ModalLanguage>

                  </div></>}
            </div>
            {/* 
            <div className='d-flex justify-content-center px-1'>
               <small className='fw-bold  text-store-header ' >  <i className="uil uil-store" ></i>Sucursal: {brachoffice_name} </small>
            </div> */}
         </header>

         {/* <div className='d-flex justify-content-center  bg-light px-1 shadow-sm'>
            <small className='fw-bold  text-store-header text-dark-emphasis' >  <i className="uil uil-store" ></i>: {brachoffice_name} </small>
         </div> */}
         {/* 
         <Lenguage
            show={modalShow}
            onHide={() => setModalShow(false)}
         /> */}
      </>


   )
}
