
import axios from 'axios';

//We set the base url of the server 
let baseURL = process.env.REACT_APP_C_SERVER_URL

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class EvertechFetchAPI {

    //Function to make a sale with evertech
    static async makeSale(data) {
        data = JSON.stringify(data);
        const res = await axios.post(`${baseURL}/evertec/sale`, data);
        return res

        /*  return {
 
             data:
             {
                 "terminal_id": "40000142",
                 "station_number": "1234",
                 "cashier_id": "0001",
                 "session_id": "K4FQT-242RR-01QXP-030UR",
                 "merchant_id": "4549102872950",
                 "reference": "000001",
                 "last_reference": "",
                 "force_duplicate": "no",
                 "process_cashback": "no",
                 "manual_entry_indicator": "no",
                 "response_message": "APPROVED",
                 "approval_code": "00",
                 "authorization_code": "123563",
                 "amounts": {
                     "total": "$5.49",
                     "state_tax": "$0.32",
                     "city_tax": "$0.05",
                     "reduced_tax": "$0.12",
                     "base_reduced_tax": "$2.00"
                 },
                 "pan_card_number": "0011",
                 "receipt_output": {
                     "merchant": "                Synergy\r\n                  SMR\r\n              P400EVERTEC\r\nDATE             TIME              HOST\r\nAUG 30, 24      123020             ATH1\r\nBATCH         TERMINAL ID   MERCHANT ID\r\n000016         40000142   4549102872950\r\n                  SALE\r\nVISA                       ********0011\r\nAUTH. CODE: 123563      INVOICE: 000140\r\nTRACE: 000181         REFERENCE: 000001\r\nAMOUNT:                        $   5.00\r\nSTATE TAX:                     $   0.32\r\nREDUCED STATE TAX:             $   0.12\r\nMUNICIPAL TAX:                 $   0.05\r\nTOTAL:                         $   5.49\r\n     X____________________________\r\n              VISA CREDIT\r\n              CONTACTLESS\r\n          AID:  A0000000031010\r\n         AC:  3B895D9BE99E853E\r\nUN:  DEDD651D TVR:  0000000000 TSI:  000\r\n0\r\nCARDHOLDER ACKNOWLEDGES RECEIPT OF GOODS\r\n AND/OR SERVICE IN THE AMOUNT OF THE TOT\r\nAL SHOWN HEREON AND AGREES TO PERFORM TH\r\nE OBLIGATIONS SET FORTH IN THE CARDHOLDE\r\nR'S AGREEMENT WITH THE ISSUER\r\n         *** MERCHANT COPY ***\r\n",
                     "customer": "                Synergy\r\n                  SMR\r\n              P400EVERTEC\r\nDATE             TIME              HOST\r\nAUG 30, 24      123020             ATH1\r\nBATCH         TERMINAL ID   MERCHANT ID\r\n000016         ****0142     *******2950\r\n                  SALE\r\nVISA                       ********0011\r\nAUTH. CODE: 123563      INVOICE: 000140\r\nTRACE: 000181         REFERENCE: 000001\r\nAMOUNT:                        $   5.00\r\nSTATE TAX:                     $   0.32\r\nREDUCED STATE TAX:             $   0.12\r\nMUNICIPAL TAX:                 $   0.05\r\nTOTAL:                         $   5.49\r\n          CONTROL: ZR65M-P7PD2\r\n              VISA CREDIT\r\n              CONTACTLESS\r\n          AID:  A0000000031010\r\n         AC:  3B895D9BE99E853E\r\nUN:  DEDD651D TVR:  0000000000 TSI:  000\r\n0\r\n                    \r\n         *** CUSTOMER COPY ***\r\n"
                 },
                 "invoice_number": "000140",
                 "transaction_time": "123020",
                 "transaction_date": "0830",
                 "special_account": "VC",
                 "batch_number": "000016",
                 "trace_number": "000181",
                 "payment_host": "ATH",
                 "card_bin_type": "VISA",
                 "card_acceptor_id": "NONE",
                 "signature_indicator": "SIGN",
                 "transaction_type_indicator": "C",
                 "fallback": "no",
                 "entry_type": "CTLS",
                 "ivu": {
                     "control_line1": "CONTROL: ZR65M-P7PD2",
                     "control_line2": "ET                  ",
                     "info_line1": "                      "
                 },
                 "emv_data": {
                     "95": "TVR: 0000000000",
                     "9F12": "VISA CREDIT",
                     "9F06": "AID: A0000000031010",
                     "9F26": "AC: 3B895D9BE99E853E",
                     "9F37": "UN: DEDD651D",
                     "9B": "TSI: 0000"
                 }
             }
         } */

        /*  return {
             data: {
                 "terminal_id": "40000142",
                 "station_number": "1234",
                 "cashier_id": "0001",
                 "session_id": "NP90X-24EJ4-017CS-037PC",
                 "reference": "000011",
                 "last_reference": "",
                 "force_duplicate": "no",
                 "process_cashback": "no",
                 "manual_entry_indicator": "no",
                 "response_message": "DECLINED (Incorrect PIN)",
                 "approval_code": "ZY",
                 "authorization_code": "",
                 "amounts": {},
                 "fallback": "no"
             }
         } */
    }

    //Function to make logon with evertech
    static async makeLogon(data) {
        data = JSON.stringify(data);
        const res = await axios.post(`${baseURL}/evertec/Logon`, data);
        return res
        /*       return {
                  data: {
                      "terminal_id": "40000142",
                      "station_number": "1234",
                      "session_id": "V34RL-24LYM-0184W-03BEK",
                      "merchant_id": "N/A",
                      "reference": "000004",
                      "response_message": "APPROVED",
                      "approval_code": "00"
                  }
              } */
    }

    //Function to make loggoff with evertech 
    static async makeLogoff(data) {
        data = JSON.stringify(data);
        const res = await axios.post(`${baseURL}/evertec/Logoff`, data);
        return res
        /*         return {
                    data: {
                        "sECRStationID": "1234",
                        "sCashierID": "001",
                        "sDefaultHostIP": "192.168.1.20",
                        "iDefaultHostPort": 2030,
                        "sDefaultTID": "40000142",
                        "sRefNumber": "001"
                    }
                } */
    }

    //Function to make settle with evertech
    static async makeSettle(data) {
        data = JSON.stringify(data);
        const res = await axios.post(`${baseURL}/evertec/cmdSettle`, data);
        return res
        /*     return {
                data: {
                    "terminal_id": "40000142",
                    "station_number": "1234",
                    "cashier_id": "0001",
                    "session_id": "NP90X-24EJ4-017CS-037PC",
                    "reference": "000001",
                    "response_message": "SETTLE\r\nATH1:  UPLOAD    015      *BATCH TOTAL OK*    \r\nATH2: No Transactions\r\nATH3: No Transactions\r\nATH4: No Transactions",
                    "approval_code": "00",
                    "receipt_output": {
                        "settle": "<!DOCTYPE html><html lang=\"en\"><head><meta charset=\"UTF-8\"/><title>MERCHANT RECEIPT</title><script type=\"text/javascript\"></script> </head><body><div id=\"caption\"><img src=\"ATHLogo.png\" alt=\"Caption\"> </div><div id=\"StoreInfo\">Synergy SMR P400<br>EVERTEC LLC<br>CUPEY<br></div><div id=\"DateTimeLabel\"><div class=\"label\">Date</div><div class=\"content\">Time</div></div><div id=\"DateTimeValue\"><div class=\"label\">AUG 28, 24</div><div class=\"content\">14:31:20</div></div><div id=\"DateTimeLabel\"><div class=\"label\">Cash Register</div><div class=\"content\">Cashier ID</div></div><div id=\"DateTimeValue\"><div class=\"label\">1234</div><div class=\"content\">0001</div></div><div id=\"HOST ATH 1\"><div class=\"title\">HOST: ATH1</div></div><div id=\"DateTimeLabel\"><div class=\"label\">Terminal ID</div><div class=\"content\">Merchant ID</div></div><div id=\"DateTimeValue\"><div class=\"label\">40000142</div><div class=\"content\">4549102872950</div></div><div id=\"HOST ATH 1\"> <div class=\"title\">STATUS:  UPLOAD    015      *BATCH TOTAL OK*    </div><div id=\"CREDIT\"> <br> <div class=\"label\">CREDIT</div> <br> <div class=\"label\">Sale:</div> <div class=\"content\">(008) $181.92</div> <br> <div class=\"label\">Refund:</div> <div class=\"content\">(003) $ -3.36</div> <br> </div> <div id=\"DEBIT\"> <br> <div class=\"label\">DEBIT</div> <br> <div class=\"label\">Sale:</div> <div class=\"content\">(003) $46.82</div> <br> <div class=\"label\">Refund:</div> <div class=\"content\">(001) $ -3.30</div> <br> </div> <div id=\"CASH\"> <br> <div class=\"label\">CASH</div> <br> <div class=\"label\">Sale:</div> <div class=\"content\">(000) $0.00</div> <br> <div class=\"label\">Refund:</div> <div class=\"content\">(000) $ -0.00</div> <br> </div> </div><br></body></html>"
                    },
                    "settlement_data": {
                        "ATH1": {
                            "Debit": {
                                "debSalesCount": "003",
                                "debSalesAmt": "000000004682",
                                "debRefundsAmt": "000000000330",
                                "debRefundsCount": "001"
                            },
                            "Credit": {
                                "credSalesAmt": "000000018192",
                                "credRefundsAmt": "000000000336",
                                "credRefundsCount": "003",
                                "credSalesCount": "008"
                            },
                            "Cash": {
                                "cashSalesCount": "000",
                                "cashRefundsAmt": "000000000000",
                                "cashRefundsCount": "000",
                                "cashSalesAmt": "000000000000"
                            },
                            "response_message": " UPLOAD    015      *BATCH TOTAL OK*    "
                        },
                        "ATH2": {
                            "response_message": "No Transactions"
                        },
                        "ATH3": {
                            "response_message": "No Transactions"
                        },
                        "ATH4": {
                            "response_message": "No Transactions"
                        }
                    }
                }
            } */
    }

    //Function to create a new payment on place to pay evertech
    static async newPayment( branchoffice_id, secretKey, loginKey, language, transaction_code, evertech_code, description, total, token) {
        const res = await server(`/evertech`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                branchoffice_id,
                secretKey,
                loginKey,
                language,
                transaction_code,
                evertech_code,
                description,
                total,
            },
        });
        return res
    }

    //Function to get buys by branchoffice on place to pay evertech
    static async getTransactionPaymentStatus(requestId, secretKey, loginKey, token) {
        const res = await server(`/evertech/${requestId}`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                secretKey,
                loginKey
            }
        });
        return res
    }

    //Function to return payment on place to pay evertech
    static async returnPayment(secretKey, loginKey, paymentInternalReference, total, token) {
        const res = await server(`/return/evertech`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                secretKey,
                loginKey,
                paymentInternalReference,
                total
            }
        })
        return res
    }

}