//React components 
import React from 'react'
//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Header } from '../../components/common/Header/Header';


function ModalWelcomePage({ showModalWelcomePage, setShowModalWelcomePage, background, tableNow, handleSaveBranchoffice, branchoffices, handleSave }) {
    let logo = localStorage.getItem('logo')

    return (
        <>
            <Modal show={showModalWelcomePage} fullscreen >


                <Modal.Body className='m-0 p-0'>
                    <section className='ecomerce-container-0 d-lg-flex justify-content-lg-center container-lg'>
                        <div className='ecomerce-container-1 position-realtive  pb-2'>
                            <div className='ecomerce-container-section-0  border rounded-4 bg-white my-4' style={{ backgroundImage: `url(${background})` }} >
                                <header className='text-center fw-bold d-flex justify-content-center  align-items-center mt-4'>
                                    <div>
                                        <img src={logo}
                                            alt="Logo Bussiness" className='img-ecommerce-header rounded-3' />
                                    </div>
                                </header>

                                <div className=" d-flex align-items-center justify-content-center  p-3">

                                    {tableNow.label != '' ? <>
                                        <div className='mt-5'>
                                            <span className='text-center fs-1'>Bienvenido a la <br></br>  {tableNow.value &&tableNow.value.is_room == 1?<>habitacion</> :<>mesa</>}: </span>
                                            <h2 className='text-center txtRoom mt-3'>{tableNow.label}</h2>
                                        </div></> : <>

                                        <div className='mt-5'>
                                            <span className='text-center display-4 fw-bold'>¡ Bienvenido !</span>
                                        </div></>}
                                </div>

                                <div className=" d-flex align-items-center justify-content-center mt-5 ">
                                    <button type="button" class="btn btn-dark bt-lg p-4" onClick={() => (handleSave())}> <h2>  {tableNow.label != '' ? <>

                                        <i class="uil uil-bed d-block display-1"></i>
                                        <span className='text-center display-4 fw-bold' >
                                            Servicio a la Habitación
                                        </span>


                                    </>

                                        : <>
                                            <i class="uil uil-book-reader  d-block display-1"></i>

                                            <span className='text-center display-4 fw-bold' >
                                                Ver Menú
                                            </span>

                                        </>}

                                    </h2>

                                    </button>
                                </div>

                            </div>

                            <div className='text-center opacity-25'>
                                <small className='text-white '> Copyright ©2025 All rights reserved
                                    by Smart Pay</small>
                            </div>
                        </div>



                    </section>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ModalWelcomePage