import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
import initData from '../Home/initData';
import { TablesFetchAPI } from '../../api/Tables';
//CSS
import './menu.css';
import { ModalProduct } from './ModalProduct';

//IMG
import all_product from '../img/icons/all.png'

export const Menu = () => {
   const navigate = useNavigate()
   const { business_code, branchoffice_code, machine_code_table } = useParams();

   let locationMe = useLocation();

   //STATES MENU
   const [categories, setCategories] = useState([]);
   const [promotions, setPromotions] = useState([]);
   const [groups, setGroups] = useState([]);
   const [allGroups, setAllGroups] = useState([]);
   const [categorieNow, setCategorieNow] = useState({});
   const [handlerEvent, setHandlerEvent] = useState(0);
   const [selectedGroup, setSelectedGroup] = useState({});
   const [segmentsGroup, setSegmentsGroup] = useState([]);
   const [shoppingcart, setShoppingcart] = useState([]);
   const [shoppingCartCombos, setShoppingCartCombos] = useState([])


   //----------------------------auth params localstorage---------------------------------------------------
   let machine = "";
   let business = "";
   let branchoffice = "";
   let logo = ""
   let params = false
   let token = localStorage.getItem("x-access-machine-token")
   if (localStorage.getItem('logo') != null || localStorage.getItem('logo') != undefined || localStorage.getItem('logo') != "") {
      logo = localStorage.getItem('logo')
   }
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {

   } else {
      params = true
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      //setBranchoffice(JSON.parse(localStorage.getItem('branchoffice')))
      machine = JSON.parse(localStorage.getItem('machine'));
      business = JSON.parse(localStorage.getItem("business"));
   }
   //---------------------------------------------------------------------------------------------------------



   const [modalShow, setModalShow] = React.useState(false);
   //----------------------Data general-----------------------\

   //consolidates data into corresponding statements
   const dataconsolidation = (generalData) => {
      if (generalData == 'error') {
         if (localStorage.getItem('table') != undefined && localStorage.getItem('table') != '' && localStorage.getItem('table') != null) {
            let dataTable = JSON.parse(localStorage.getItem('table'))
            let codeTable = dataTable.table_code
            navigate(`/branch/${business_code}/${codeTable}`)
         } else {
            navigate(`/branch/${business_code}`)
         }
      }
      let data = generalData

      //all groups--------------------------------------------------------
      //section categories----------------------------------------------
      let dataGroups = data.groups
      let groups_all = dataGroups.filter(({ status }) => status === 1);
      setAllGroups(groups_all)
      //------------------------------------------------------------------

      //section categories------------------------------------------------
      let dataCategories = data.category
      let typeCodeCategories = 'P'
      let categories_info = dataCategories.filter(({ code }) => code === typeCodeCategories);
      setCategories(categories_info)

      //---------------------------------------------------------------------
      //selected categorie---------------------------------------------------

      let selectCategorie = localStorage.getItem('selectedCategorie')
      if (selectCategorie != null && selectCategorie != undefined && selectCategorie != '') {

         let categorie = JSON.parse(selectCategorie)
         setCategorieNow(categorie)

         let allProducts = categorie.groups
         let arrayCombos = categorie.combos
         arrayCombos.forEach((dataCombos) => {
            let priceCombo = 0
            if (dataCombos.setGroups.length > 0) {
               dataCombos.setGroups.forEach((combo) => {
                  //if (combo.isMainSetGroup == 1) {
                  if (combo.groups.length > 0) {
                     priceCombo = (combo.groups[0].price + priceCombo)
                  }
                  //}
               })
               dataCombos.price = priceCombo
            }
         })
         let products = allProducts.concat(categorie.combos)

         setGroups(products);
      } else {

         if (categories_info.length > 0) {
            localStorage.setItem('selectedCategorie', JSON.stringify(categories_info[0]));
            setCategorieNow(categories_info[0])
            setGroups(categories_info[0].groups);
            setHandlerEvent(1)
         } else { navigate(`/home/${business_code}/${branchoffice_code}/${machine_code_table}`) }


      }


      //--------------------------DATA SHOPPINGCART-------------------------------------------
      if (localStorage.getItem('shoppingCart') == null || localStorage.getItem('shoppingCart') == undefined || localStorage.getItem('shoppingCart') == "") {
         setShoppingcart([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCart'))
         setShoppingcart(datashopingcart)
      }

      if (localStorage.getItem('shoppingCartCombos') == null || localStorage.getItem('shoppingCartCombos') == undefined || localStorage.getItem('shoppingCartCombos') == "") {
         setShoppingCartCombos([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCartCombos'))
         setShoppingCartCombos(datashopingcart)
      }
   }

   //========================================================================================
   const selectCategorie = (categorie) => {
      localStorage.setItem('selectedCategorie', JSON.stringify(categorie))
      setCategorieNow(categorie)


      let allProducts = categorie.groups
      let arrayCombos = categorie.combos
      arrayCombos.forEach((dataCombos) => {
         let priceCombo = 0
         if (dataCombos.setGroups.length > 0) {
            dataCombos.setGroups.forEach((combo) => {
               //if (combo.isMainSetGroup == 1) {
               if (combo.groups.length > 0) {
                  priceCombo = (combo.groups[0].price + priceCombo)
               }
               //}
            })
            dataCombos.price = priceCombo
         }
      })
      let products = allProducts.concat(categorie.combos)

      setGroups(products);
      setHandlerEvent(1)
   }

   //=========================================================================================
   const selectProduct = (data) => {
      console.log(data);
      setModalShow(true)
      setSelectedGroup(data)
      if (data.segments) {
         setSegmentsGroup(data.segments)
      } else {
         setSegmentsGroup([])
      }

   }

   //============================================================================================
   const handlerInit = async () => {
      try {
         let response = await initData()
         dataconsolidation(response)
      } catch (err) {

         if (localStorage.getItem('table') != undefined && localStorage.getItem('table') != '' && localStorage.getItem('table') != null) {
            let dataTable = JSON.parse(localStorage.getItem('table'))
            let codeTable = dataTable.table_code
            navigate(`/branch/${business_code}/${codeTable}`)
         } else {
            navigate(`/branch/${business_code}`)
         }
         console.log(err);
      }
   }

   //================================================================================================
   const getTableById = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {

         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table

         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            }

         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }

   }


   useEffect(() => {
      if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
         getTableById()
      }
      handlerInit()
   }, [])

   useEffect(() => {
      setHandlerEvent(0)
   }, [handlerEvent])

   return (
      <section className='ecomerce-container-0 d-lg-flex justify-content-lg-center container-lg '>
         <div className='ecomerce-container-1 position-realtive pb-2'>
            <Header shoppingcart={shoppingcart} shoppingCartCombos={shoppingCartCombos}></Header>

            <div className='d-flex justify-content-between align-items-center'>
               <NavLink to={`/home/${business_code}/${branchoffice_code}/${machine_code_table}`}  >
                  <button className='btn btn-outline-secondary rounded-pill   p-0 border-0'>
                     <span className='d-flex align-items-center fw-bold px-3 py-1'> <i class="uil uil-arrow-left icon-header-ecommerce"></i>  Inicio </span>
                  </button>
               </NavLink>

               <h5 className='text-white fw-bold text-center m-0'>Productos</h5>
            </div>
            <div>
               <div className="container-list-categories d-flex overflow-auto py-1">

                  {categories.map((categorie) => (

                     <>

                        {/* <div className='list-pre-orders-inputs w-100' key={categorie.category_id}>
                           <input
                              className="list-group-item-check "
                              type="radio"
                              id={categorie.category_id}
                              name="checked1"
                              checked={categorieNow.category_id == categorie.category_id ? true : false}
                              onChange={() => selectCategorie(categorie)} />
                           <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-0 categories-menu shadow-sm"
                              htmlFor={categorie.category_id}
                           >

                              <div className="d-block px-1">
                                 <span className={`text-categories-menu 
                                       text-center ${categorie.category_name.length > 10 ? 'text-categories-menu-slider ' : 'text-categories-menu '}`}>
                                    {categorie.category_name}
                                 </span>
                              </div>
                           </label>
                        </div> */}




                        <label class="card-categories-input mx-2 border-0 rounded-3" htmlFor={categorie.category_id}>

                           <input nameName="plan" class="radio-categories" type="radio"
                              id={categorie.category_id}
                              checked={categorieNow.category_id == categorie.category_id ? true : false}
                              onChange={() => selectCategorie(categorie)} />

                           <span className="plan-details-categories-input">
                              <span className="plan-type-radio-categories">      {categorie.category_name} </span>
                           </span>
                        </label>

                     </>



                  ))}
               </div>
            </div>




            <div className='container-fluid ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0'>
               <div className='row  items-product-menu'>
                  {groups.length === 0
                     ? <>

                        <span className='mt-0 px-4'>

                           <div class="position-relative p-4 text-center text-muted bg-body border border-dashed rounded-4 ">

                              <span className='d-block  icon-sad-lg opacity-25'>
                                 <i class="uil uil-sad-squint"></i>
                              </span>
                              ¡La categoría seleccionada no
                              contiene productos en este momento!
                           </div>
                        </span>

                     </>
                     : <></>}

                  {groups.map((group) => (
                     // <div
                     //    key={group.group_id}
                     //    className="ecommerce-menu-card d-flex align-items-center bg-white rounded-3 
                     //    mt-1 shadow-sm mx-1 p-1 border position-relative"
                     //    onClick={() => (selectProduct(group))}
                     // >
                     //    <div className="flex-shrink-0">
                     //       <img
                     //          src={group.img}
                     //          className="img-ecommerce-menu-card rounded-3"
                     //          alt={group.name}
                     //       />
                     //    </div>
                     //    <div className="flex-grow-1 ms-3">
                     //       <div className="mt-2 me-2">
                     //          <span className="mt-1 title-ecommerce-menu-card">{group.name}</span>
                     //          <div className='d-flex justify-content-between align-items-center'>

                     //             <span className="mt-1 d-block  value-ecommerce-menu-card">$ {parseFloat(group.price).toFixed(2)}</span>

                     //          </div>

                     //       </div>
                     //    </div>

                     //    <div className='position-absolute bottom-0 end-0 me-1'>
                     //       <i class="uil uil-plus-circle text-muted opacity-50"></i>
                     //    </div>
                     // </div>





                     <>


                        <div className='col-6 col-md-3 mt-2'>
                           <div className="card-categories border border-1 rounded-3  p-1 h-100" onClick={() => (selectProduct(group))}>
                              <div className="d-flex justify-content-start position-relative">
                                 <img src={group.img}
                                    alt="Product" className=" img-favorite-menu  rounded-3" />

                                 <span class="badge badge-ranking rounded-2  position-absolute badge-ranking-position"><i class="uil uil-favorite"></i>5.0</span>

                              </div>
                              <span className="card-recomend-title" >
                                 {group.name}
                              </span>
                              <span className="card-recomend-description" >
                                 {group.description}
                              </span>

                              <span className="card-recomend-price" >
                                 ${group.price}
                              </span>

                           </div>
                        </div>
                     </>

                  ))}
               </div>


            </div>

            <ModalProduct
               modalShow={modalShow}
               setModalShow={setModalShow}
               selectedGroup={selectedGroup}
               segmentsGroup={segmentsGroup}
               shoppingcart={shoppingcart}
               shoppingCartCombos={shoppingCartCombos}
               setShoppingCartCombos={setShoppingCartCombos}
               logo={logo}
               branchoffice={branchoffice}
               token={token}
            />


         </div>
         {/* <BarMenu shoppingcart={shoppingcart} shoppingCartCombos={shoppingCartCombos}></BarMenu> */}
      </section >
   )
}
