import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { ClientsFetchAPI } from '../../api/Clients';
const RegisterUser = ({ modalUserRegister, setModalUserRegister }) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [nickNamel, setNickName] = useState('')
    const [password, setPassword] = useState('')
    const [Ruc, setRuc] = useState('')
    const [Dv, setDv] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')


    const cleanInputs = () => {
        setName('')
        setPhone('')
        setNickName('')
        setPassword('')
        setPasswordCheck('')

        setModalUserRegister(false)
    }





    const createClient = async () => {
        LoadingAlert('Creando usuario')
        let token = localStorage.getItem('x-access-machine-token')
        let business = JSON.parse(localStorage.getItem('business'))

        let data = {
            name: name,
            phone: phone,
            nickname: nickNamel,
            password: password,
            ruc: Ruc,
            dv: Dv,
            passwordCheck: passwordCheck,
            business_code: business.code,
            client_type_id: 2
        }
        try {
            const response = await ClientsFetchAPI.createClient(data, token)
            ErrorAlert('', 'Usuario creado con exito', 'success')
            cleanInputs()
        } catch (err) {
            ErrorAlert('', 'Error al crear usuario', 'error')
            console.log(err)
        }
    }


    return (
        <>
            <Modal
                show={modalUserRegister}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>
                    {/* <div className='header-profile-banner-login position-relative'>
                  <img className='header-profile-img'
                     src="https://images.unsplash.com/photo-1466978913421-dad2ebd01d17?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />

                  <div className='header-profile-text-banner position-absolute '>
                     <div className=' text-center mt-5 text-white fw-bold'>
                        ¡Ingresa ahora!
                     </div>
                  </div> 
               </div> */}
                    <div className='position-absolute '>
                        <div className='fw-bold badge rounded-2 text-bg-success'>
                            <i class="uil uil-chat-bubble-user"></i>  Usuario
                        </div>
                    </div>

                    <h2 className={'kiosk-delivery_title text-center text-success '}>
                        Registro de Usuario
                    </h2>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="text-dark rounded-4 position-relative text-wrap bg-white   p-2">
                            <div className='d-flex'>
                                <Form>
                                    <Form.Group className="mb-3 kiosk-text-card" controlId="nickName">
                                        <span className="fw-bold">Ingresa nombre completo </span>
                                        <Form.Control className='kiosk-text-card border' type="text" placeholder="  Nombre" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 kiosk-text-card" controlId="Name">
                                        <span className="fw-bold">Ingresa nombre de usuario </span>
                                        <Form.Control className='kiosk-text-card border' type="text" placeholder="nombre de usuario" onChange={(e) => setNickName(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 kiosk-text-card" controlId="Phone">
                                        <span className="fw-bold">Ingresa telefono </span>
                                        <Form.Control className='kiosk-text-card border' type="text" placeholder="6000-0000" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 kiosk-text-card " controlId="exampleForm.ControlInput1">
                                        <span className="fw-bold">Ingresa la contraseña</span>
                                        <Form.Control className='kiosk-text-card border' type="password" placeholder="*********" onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 kiosk-text-card " controlId="exampleForm.ControlInput1">
                                        <span className="fw-bold">Ingresa la contraseña nuevamente</span>
                                        <Form.Control className='kiosk-text-card border' type="password" placeholder="*********" onChange={(e) => setPasswordCheck(e.target.value)} />
                                    </Form.Group>

                                    <div className='d-flex justify-content-center align-item-center'>
                                        <details className='' close>
                                            <summary className='sumamary-text bg-primary-subtle primary-success
                         p-1 rounded-3'><i class="uil uil-edit">Ruc / Dv</i>
                                                
                                            </summary>
                                            <div>
                                                <Form>
                                                    <div className='row d-flex justify-content-center align-item-center'>
                                                        <Form.Group className="mb-3 " controlId="formBasicText">
                                                            <Form.Label >Numero de Ruc</Form.Label>
                                                            <Form.Control placeholder="0-0000-0" onChange={(e) => (setRuc(e.target.value))} />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 " controlId="formBasicText">
                                                            <Form.Label >Numero DV</Form.Label>
                                                            <Form.Control type="number" placeholder="00" onChange={(e) => (setDv(e.target.value))} />
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                            </div>


                                        </details></div>


                                </Form>
                            </div>



                        </div>
                    </div>

                    <div className='d-flex justify-content-between '>
                        <button className={`m-1 btn btn-danger rounded btn-sm kiosk-subtitle-md position-relative px-4 `} onClick={() => (cleanInputs())}>
                            Cancelar
                        </button>
                        <button className={`m-1 btn btn-primary rounded btn-sm kiosk-subtitle-md position-relative px-4 `} onClick={() => createClient()}>
                            Registrar
                        </button>
                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
               <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default RegisterUser