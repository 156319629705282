import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { EvertechFetchAPI } from '../../api/Evertech';
import { Button } from 'react-bootstrap';

//imgs
import logo from '../../assets/img/logos/visa.svg'
import { RenderIf } from '../common/utils/RenderIf';

const Payments = ({ modalShow, setModalShow, getTableById, payments, invoiceTotal, selectedPayment, setSelectedPayment, setShowModalConfirmation, getOrderNumber, registernewOrder, processPayment, tableInfo, evertechPaymentPlaceToPay, enabled, setEnabled }) => {
   let title_categori = 'Ensalada552'



   const dataSelectPayment = (data) => {
      //getOrderNumber()
      //getTableById('', data)

      if (data.payment_id == 14) {
         evertechPaymentPlaceToPay(data)
      } else {
         setEnabled(false)
      }
      setSelectedPayment(data);
   }

   



   return (
      <>
         <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            fullscreen
            centered
         >

            <Modal.Body className='p-0'>
              
            <section className='ecomerce-container-product-0'>
                  <div className='ecomerce-container-1 position-relative'>
                     <div className='position-relative'>

                        <div className=' p-2 w-100 d-flex justify-content-between align-items-center  '>
                           <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={() => setModalShow(false)}>
                              <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                           </button>
                           <h4 className='fw-bold text-center'>
                              <i class="uil uil-bill me-1"></i>
                              Área de Pago
                           </h4>
                           {/* <button className='btn btn-header-ecommerce-product   btn-sm rounded-3'>
                              <i class="uil uil-info icon-header-ecommerce"></i>
                           </button> */}
                           <span className='px-3'></span>
                        </div>

                     </div>
                     <div className='p-1'>
                        <div className='py-3 text-center'>
                           <h1 className=' text-info-emphasis display-1 fw-bold'>$ {invoiceTotal.toFixed(2)} </h1>
                           <small className='fw-light'>Total a pagar </small>
                        </div>
                    
                     </div>
                     <div className='ecomerce-container-info mx-2'>
                        <h4 className='ecomerce-container-info-title fw-bold mt-2'>Métodos de pago</h4>
                     </div>
                     <div className='ecomerce-container-section-0 rounded-2 border border-1 border-primary bg-light pb-5 px-3 mx-2'>


                        {tableInfo.name != "" ? <>
                           {tableInfo.is_room == 1 ? <><div className='my-1 mx-1 list-pre-orders-inputs' onClick={() => setShowModalConfirmation(true)}>
                              {/*  <input
                              className="list-group-item-check-pays "
                              type="radio"
                              name="option_payments" id={'roomPay'}
                           />

                           <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1 shadow-sm"
                              htmlFor={'roomPay'}
                           >*/}
                              <div className='list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1 shadow-sm'>
                                 <div className="d-flex align-items-center gap-3 py-1 rounded" >

                                    <div className="d-flex gap-2 justify-content-around py-2">
                                       <span className="mb-3 text-wrap">
                                          <i class="uil uil-bed-double"> Servicio a la habitación</i>
                                       </span>
                                    </div>
                                 </div>
                                 <span class="badge bg-secondary-subtle text-secondary-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">

                                 </span>
                              </div>
                              {/*</label>*/}
                           </div></> : <></>}</> : <></>}







                        {payments.map((payment) => (

                           <>
                              {/* <div className='my-1 mx-1 list-pre-orders-inputs' >
                                 <input
                                    className="list-group-item-check-pays "
                                    type="radio"
                                    name="option_payments" id={'payment' + payment.payment_id}
                                    onChange={() => dataSelectPayment(payment)}

                                 />

                                 <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1 shadow-sm"
                                    htmlFor={'payment' + payment.payment_id}
                                 >

                                    <div className="d-flex align-items-center gap-3 py-1 rounded" >
                                       <img src={payment.img}
                                          alt="Product" width="80" height="80" className="rounded border flex-shrink-0  img-payment" />
                                       <div className="d-flex gap-2 justify-content-around py-2">
                                          <span className="mb-3 text-wrap">
                                             {payment.payment}
                                          </span>
                                       </div>
                                    </div>
                                    <span class="badge bg-secondary-subtle text-secondary-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">

                                    </span>
                                 </label>

                              </div> */}


                              <div className=' mt-3'>
                                 <input type="radio" class="btn-check"
                                    name="option_payments" id={'payment' + payment.payment_id}
                                    onChange={() => dataSelectPayment(payment)}

                                 />
                                 <label className="btn btn-outline-primary w-100" for={'payment' + payment.payment_id}>
                                    <div class="d-flex align-items-center">
                                       <div class="flex-shrink-0">
                                          <img src={payment.img}
                                             alt="Product" width="80" height="80" className="rounded-2 border  bg-primary" />

                                       </div>
                                       <div class="flex-grow-1 ms-3">
                                          <span className="d-block p-2  text-start fw-bold h5">
                                             {payment.payment}
                                          </span>
                                       </div>
                                    </div>
                                 </label>
                              </div>



                           </>

                        ))}





                        {/* <div className='p-1'>
                        <div className='d-flex justify-content-between align-items-center'>
                           <span className='item-value-product'>
                              $ 18.00
                           </span>
                           <div className='d-flex justify-content-around gap-1 align-items-center'>
                              <span><i class="uil uil-minus-circle item-count-product"></i></span>
                              <span className=' item-count-product'>0</span>
                              <span><i class="uil uil-plus-circle item-count-product"></i></span>
                           </div>
                        </div>
                        <button className='btn btn-add-product w-100' onClick={props.onHide} > Agregar al carrito</button>
                      */}
                     </div>
                     <div className='flex-column'>
                        <div className=' flex-column'>
                           <div className='d-flex justify-content-between align-items-center '>
                              {/*  <button className='btn btn-add-product w-100 p-2 m-1' onClick={() => setShowModalConfirmation(true)}  >
                              Ordernar
                              $ {invoiceTotal} 
                           </button>*/}

                              <button disabled={enabled} className='btn btn-primary w-100 p-2 m-1' onClick={() => processPayment(selectedPayment)}  >
                                 Pagar: $ {invoiceTotal.toFixed(2)}
                              </button>
                           </div>
                        </div>

                     </div>

                     <div className='py-2'></div>
                  </div>
               </section>
               



            </Modal.Body>


         </Modal>



      </>
   )
}

export default Payments