import React, { useState, useEffect, useMemo, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
import { Button } from 'react-bootstrap';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
//CSS
import './orders.css';
import InfoOrdersModal from './InfoOrdersModal';
import { PreOrderFetchAPI } from '../../api/PaymentPost/PreOrder';
import { OrdersFetchAPI } from '../../api/Orders';
import { TablesFetchAPI } from '../../api/Tables';
import Success from '../Mesage/Success';
import Danger from '../Mesage/Danger';
import Default from '../Mesage/Default';
import moment from 'moment-timezone';
import Socket from '../../utils/Socket.config';
import ErrorAlert from '../Alerts/ErrorAlert';
import { RenderIf } from '../common/utils/RenderIf';
import LoginUser from '../Profile/LoginUser';

export const Orders = () => {
   const navigate = useNavigate();
   const [modalUser, setModalUser] = React.useState(false);
   const [modalShow, setModalShow] = React.useState(false);
   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [selectedOrder, setSelectedOrder] = useState({ groups: [], combos: [], total: 0, code: "" });
   const [minDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
   const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00');
   const [temporalOrdersDeletedData, setTemporalOrdersDeletedData] = useState([]);
   const [temporalOrdersSuccessData, setTemporalOrdersSuccessData] = useState([]);
   const [eventHandler, setEventHandler] = useState(0);
   //state
   const [orders, setOrders] = useState([]);
   const [OrdersPending, setOrdersPending] = useState([]);

   let table = localStorage.getItem('table');
   let token = localStorage.getItem('x-access-machine-token');
   let machine = localStorage.getItem('machine');
   let branchoffice = localStorage.getItem('branchoffice');

   if (machine !== null && machine !== undefined && machine !== '' && branchoffice !== null && branchoffice !== undefined && branchoffice !== '') {

      machine = JSON.parse(localStorage.getItem('machine'));
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
   }

   if (table != null && table != undefined && table != '') {
      table = JSON.parse(localStorage.getItem('table'));
   } else {
      table = 0
   }

   const [tableInfo, setTableInfo] = useState(0)


   const getOrdersByBranchByTableAndUser = async (client) => {
      let table_id = 0
      let client_id = 0
      if (table != 0) {
         table_id = table.id
      }

      if (client != undefined) {
         client_id = client.id
      }

      console.log("Table:   ======", table_id);
      try {
         const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyTable(business_code, branchoffice_code, minDate, maxDate, 0, 0, machine.id, table_id, token, client_id);
         setOrders(response.data.orders);
      } catch (err) {
         setOrders([])
         console.log(err);
      }

   }

   const getOrderByTransactionCode = async (order) => {
      try {
         const response = await OrdersFetchAPI.getTransactionByCode(order.transaction_code, business_code, branchoffice_code, token);
         setSelectedOrder(response.data.order)
         console.log(response.data.order);
         navigate(`/order/${response.data.order.code}/branchoffice/${branchoffice.id}/${business_code}/${branchoffice_code}/${machine_code_table}`);
      } catch (err) {
         console.log(err);
      }
   }




   const getPreOrdertablet = async () => {
      try {
         const response = await PreOrderFetchAPI.getTablePreOrder(table.table_code, business_code, branchoffice_code, token)
         let ordersFilter = response.data.orders.filter((order) => order.table_password && order.table_password == table.password);
         setOrdersPending(ordersFilter);

         console.log(ordersFilter);
      } catch (err) {
         setOrdersPending([]);
         console.log(err);
      }
   }

   // function get order deleted
   const getDeleteTemporal = async () => {
      try {
         const response = await PreOrderFetchAPI.getDeletedTransactionReport(business_code, branchoffice_code, minDate, maxDate, 0, token, table.table_code);
         console.log(response.data.temp_orders);

         let dataOrders = response.data.temp_orders;

         console.log(dataOrders);
         let dataSuccess = []
         let dataDeleted = []
         dataOrders.forEach((item) => {
            if (item.deleted == true) {
               dataDeleted.push(item)
            } else {
               dataSuccess.push(item);
            }
         });

         let ordersFilterDelete = dataDeleted.filter((order) => order.table_password && order.table_password == table.password);
         let ordersFilterSuccess = dataSuccess.filter((order) => order.table_password && order.table_password == table.password);


         setTemporalOrdersDeletedData(ordersFilterDelete);
         setTemporalOrdersSuccessData(ordersFilterSuccess)

      } catch (err) {
         console.log(err);
         setTemporalOrdersDeletedData([]);
      }

   }

   // get Order deleted by id
   const getDeleteOrderTemporalById = async (_id) => {
      try {
         const response = await PreOrderFetchAPI.getDeletedTransactionById(_id, token);
         setModalShow(true)
         setSelectedOrder(response.data.deleted_order)
      } catch (err) {
         console.log(err);
      }
   }

   const emptyOrders = () => {
      return (<><span className='mt-0 px-4 mt-5'>

         <div class="position-relative p-4 text-center text-muted bg-body rounded-4 mx-3">

            <span className='d-block  icon-sad-lg opacity-25'>
               <i class="uil uil-sad-squint"></i>
            </span>
            ¡No hay transacciones!
         </div>
      </span></>)
   }

   //================================================================================================
   const getTableById = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {

         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table

         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            }

         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }

   }



   useEffect(() => {

      if (table == 0) {
         if (localStorage.getItem('client') !== null && localStorage.getItem('client') !== undefined && localStorage.getItem('client') !== '') {
            let client = JSON.parse(localStorage.getItem('client'))
            getOrdersByBranchByTableAndUser(client)
         } else {
            setModalUser(true)
         }
         setTableInfo(0)
      } else {
         if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
            getTableById()
         }
         getPreOrdertablet();
         getOrdersByBranchByTableAndUser()
         getDeleteTemporal();
         setTableInfo(table)
      }
      setEventHandler(0)
   }, [eventHandler]);


   const [selectedTab, setSelectedTab] = useState("catogire1");

   const handleTabChange = (event) => {
      setSelectedTab(event.target.id);
   }


   const [temporalOrdersData, setTemporalOrdersData] = useState([]);
   //Hook to conect the client to the sockets and make the initial charge of data 
   useEffect(() => {

      if (localStorage.getItem('table') !== null && localStorage.getItem('table') !== undefined && localStorage.getItem('table') !== '') {
         // Connection with the sockets server
         const socket = Socket({ "business_code": business_code })
         // we upate the data when the server send the signal of a new order 

         // Update of business temp orders (discount and devolutions)
         socket.on(`businessTempOrder_${business_code}`, (newTempOrder) => {
            let selected_branch = localStorage.getItem('branchoffice_selected')
            //We set the temporal orders data 
            console.log(newTempOrder);
            setOrders((prevTempOrders) => {

               if (newTempOrder.table_password && newTempOrder.table_code == table.table_code && newTempOrder.table_password == table.password) {
                  return [newTempOrder, ...prevTempOrders]

               } else {
                  return prevTempOrders
               }

            });
         });

         //Update of business temp orders when the temp order is deleted 
         socket.on(`deleteBusinessTempOrder_${business_code}`, (deletedTempOrder) => {
            console.log("Eliminada");
            console.log(deletedTempOrder);
         });

         return () => {
            socket.disconnect();
         }
      }
   }, [business_code]);

   return (
      <>


         <section className='ecomerce-container-0 d-lg-flex justify-content-lg-center container-lg'>
            <div className='ecomerce-container-1 position-realtive pb-2'>
               <Header ></Header>

               <div className='d-flex justify-content-between align-items-center'>
                  <NavLink to={`/home/${business_code}/${branchoffice_code}/${machine_code_table}`}  >
                     <button className='btn btn-outline-secondary rounded-pill   p-0 border-0'>
                        <span className='d-flex align-items-center fw-bold px-3 py-1'> <i class="uil uil-arrow-left icon-header-ecommerce"></i>  Inicio </span>
                     </button>
                  </NavLink>

                  <h5 className='text-white fw-bold text-center m-0'>Ordenes</h5>
               </div>
               <div className="container-list-categories d-flex overflow-auto py-4">
                  {/* 1 */}
                  <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire1">

                     <input name="categories" class="radio-categories" type="radio"
                        id="catogire1"
                        checked={selectedTab === "catogire1"}
                        onChange={handleTabChange}
                     />

                     <span className="plan-details-categories-input">
                        <span className="plan-type-radio-categories"> <i class="uil uil-bill"></i>  Facturado  </span>
                     </span>
                  </label>

                  {tableInfo != 0 ? <>
                     {/* 2 */}
                     <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire2">

                        <input name="categories" class="radio-categories" type="radio"
                           id="catogire2"
                           checked={selectedTab === "catogire2"}
                           onChange={handleTabChange}
                        />

                        <span className="plan-details-categories-input">
                           <span className="plan-type-radio-categories">   <i class="uil uil-spinner-alt"></i>     Pendiente  </span>
                        </span>
                     </label>
                     {/* 3 */}
                     <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire3">

                        <input name="categories" class="radio-categories" type="radio"
                           id="catogire3"

                           checked={selectedTab === "catogire3"}
                           onChange={handleTabChange}
                        />

                        <span className="plan-details-categories-input">
                           <span className="plan-type-radio-categories"><i class="uil uil-check-circle"></i>     Listos  </span>
                        </span>
                     </label>

                     <label class="card-categories-input mx-2 border-0 rounded-3" id="catogire4">

                        <input name="categories" class="radio-categories" type="radio"
                           id="catogire4"

                           checked={selectedTab === "catogire4"}
                           onChange={handleTabChange}
                        />

                        <span className="plan-details-categories-input">
                           <span className="plan-type-radio-categories"><i class="uil uil-times-circle"></i> Anulados  </span>
                        </span>
                     </label></> : <></>}


               </div>
               <div className='ecomerce-container-section-0  border rounded-4 bg-white pt-0 position-relative'>

                  <div className='position-fixed bottom-0 end-0 z-3 m-3'>
                     <button className='btn btn-primary rounded-circle py-2 shadow' onClick={() => (setEventHandler(1), ErrorAlert('', 'Ordenes actualizadas', 'success'))}><i class="uil uil-refresh"></i></button>
                  </div>


                  {/* Contenido basado en el tab seleccionado */}
                  <div className="tab-content">

                     {selectedTab === "catogire1" && (
                        <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                           {orders.length === 0 ? <>{emptyOrders()}</> : <>
                              {orders.map((order, index) => (<>

                                 <article className="card-list-orders"><span class="badge badge-success  text rounded-2  position-absolute badge-position"> <i class="uil uil-bill"></i> Facturado</span>


                                    <span className="card-list-orders-title">
                                       <div>
                                          <div className="w-100 p-3">

                                             <div className="d-flex justify-content-center gap-3 mb-1 mt-3 ">
                                                <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>
                                                <h2 className=" text-dark fs-4"> <b>{order.transaction_code} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                             </div>
                                             <div className='d-flex justify-content-center'>
                                                <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                   onClick={() => (getOrderByTransactionCode(order))}
                                                >Ver más</button>
                                             </div>

                                          </div>
                                       </div>
                                    </span>
                                 </article>  </>))} </>}


                        </div>
                     )}

                     <RenderIf isTrue={tableInfo != 0}>
                        {selectedTab === "catogire2" && (
                           <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                              {OrdersPending.length === 0 ? <>{emptyOrders()}</> : <>
                                 {OrdersPending.map((order, index) => (<>

                                    <article className="card-list-orders">
                                       {order.is_authorized == false ? <span class="badge badge-pending  text rounded-2  position-absolute badge-position"><i class="uil uil-history-alt"></i> En revision</span> : <><span class="badge badge-processing text rounded-2  position-absolute badge-position "> <i class="uil uil-spinner-alt"></i> Pendiente</span></>}

                                       <span className="card-list-orders-title">
                                          <div>
                                             {/* <img src="https://images.unsplash.com/photo-1508737027454-e6454ef45afd?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""

               className="object-fit-cover" /> */}
                                             <div className="w-100 p-3">
                                                {/* <div className="d-flex justify-content-between gap-3 mb-1">
               <span class="badge bg-light bg-gradient text-dark">
                  Orden: #26300
               </span>

            
            </div> */}
                                                <div className="d-flex justify-content-center gap-3 mb-1 mt-3 ">
                                                   <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>
                                                   <h2 className=" text-dark fs-4"> <b>{order.code}  </b></h2>
                                                </div>

                                                <div className="d-flex justify-content-between gap-3 mb-1">
                                                   <h2 className=" text-secondary fs-6"><i class="uil uil-bed"></i> <b>Habitacion: </b></h2>
                                                   <h2 className=" text-secondary fs-6"> <b> {order.table_name} </b></h2>
                                                </div>

                                                <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                   <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                   <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                                </div>


                                                <div className='d-flex justify-content-center'>
                                                   <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                      onClick={() => (setModalShow(true), setSelectedOrder(order))}

                                                   >Ver más</button>
                                                </div>

                                             </div>
                                          </div>



                                       </span>
                                    </article>  </>))} </>}


                           </div>
                        )}
                        {selectedTab === "catogire3" && (
                           <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                              {temporalOrdersSuccessData.length === 0 ? <>{emptyOrders()}</> : <>
                                 {temporalOrdersSuccessData.map((order, index) => (<>

                                    <article className="card-list-orders">
                                       <span class="badge badge-success text rounded-2  position-absolute badge-position"> <i class="uil uil-check-circle"></i> Listo</span>
                                       <span className="card-list-orders-title">
                                          <div>
                                             {/* <img src="https://images.unsplash.com/photo-1508737027454-e6454ef45afd?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""

className="object-fit-cover" /> */}
                                             <div className="w-100 p-3">
                                                {/* <div className="d-flex justify-content-between gap-3 mb-1">
<span class="badge bg-light bg-gradient text-dark">
Orden: #26300
</span>


</div> */}
                                                <div className="d-flex justify-content-center gap-3 mb-1 ">
                                                   <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>


                                                   <h2 className=" text-dark fs-4"> <b> {order.code} </b></h2>
                                                </div>

                                                <div className="d-flex justify-content-between gap-3 mb-1">
                                                   <h2 className=" text-secondary fs-6"><i class="uil uil-bed"></i> <b>Habitacion: </b></h2>

                                                   <h2 className=" text-secondary fs-6"> <b> {order.table_name} </b></h2>
                                                </div>

                                                <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                   <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                   <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                                </div>


                                                <div className='d-flex justify-content-center'>
                                                   <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                      onClick={() => (getDeleteOrderTemporalById(order._id))}

                                                   >Ver más</button>
                                                </div>

                                             </div>
                                          </div>




                                       </span>
                                    </article>  </>))}</>} </div>
                        )}
                        {selectedTab === "catogire4" && (
                           <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                              {temporalOrdersDeletedData.length === 0 ? <>
                                 {emptyOrders()}
                              </> : <>
                                 {temporalOrdersDeletedData.map((order, index) => (<>

                                    <article className="card-list-orders">
                                       <span class="badge badge-danger text rounded-2  position-absolute badge-position"> <i class="uil uil-times-circle"></i>  Anulados</span>
                                       <span className="card-list-orders-title">
                                          <div>
                                             {/* <img src="https://images.unsplash.com/photo-1508737027454-e6454ef45afd?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""

className="object-fit-cover" /> */}
                                             <div className="w-100 p-3">
                                                {/* <div className="d-flex justify-content-between gap-3 mb-1">
<span class="badge bg-light bg-gradient text-dark">
Orden: #26300
</span>

</div> */}
                                                <div className="d-flex justify-content-center gap-3 mb-1 ">
                                                   <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>


                                                   <h2 className=" text-dark fs-4"> <b> {order.code} </b></h2>
                                                </div>

                                                <div className="d-flex justify-content-between gap-3 mb-1">
                                                   <h2 className=" text-secondary fs-6"><i class="uil uil-bed"></i> <b>Habitacion: </b></h2>

                                                   <h2 className=" text-secondary fs-6"> <b> {order.table_name} </b></h2>
                                                </div>

                                                <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                   <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                   <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                                </div>


                                                <div className='d-flex justify-content-center'>
                                                   <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                      onClick={() => (getDeleteOrderTemporalById(order._id))}

                                                   >Ver más</button>
                                                </div>

                                             </div>
                                          </div>



                                          {/*<details className='rounded-4'>
<summary>Estado de Orden</summary>
<div class="order-track">
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text ">
<p class="order-track-text-stat">Order Received</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Processed</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Manufracturing In Progress</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Dispatched</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Deliverd</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
</div>
</details>*/}
                                       </span>
                                    </article>  </>))} </>}</div>
                        )}</RenderIf>
                  </div>
               </div>
            </div>

            {/* <BarMenu></BarMenu> */}
         </section>

         <InfoOrdersModal modalShow={modalShow}
            setModalShow={setModalShow} selectedOrder={selectedOrder} />
         <LoginUser modalUser={modalUser} setModalUser={setModalUser} />
      </>



   )
}
